<template>
    <div class="side-cart">

        <a href="#" @click="toggleCartView" class="mobile-only add-more"><i class="icon arrow left blue" style="margin-right: .3em;"></i>Add more products</a>

        <ProductCartRxAdd />
        <h1 class="mobile-only" style="margin:.375em 0;">Your patient's regimen</h1>

        <div v-if="cart.cartItems.length > 0">
            <div v-for="condition in cart.activeConditions" v-bind:key="condition.id">
                <h3 class="condition-label">{{ condition.name }}</h3>
                <TransitionGroup name="list" class="cart-list-wrapper" tag="div">
                    <ProductCartTile class="" v-for="item in productCondition(condition.name)" v-bind:key="item.id" :product="item"
                    @remove-row="toggleCartItem"  @editProduct="editOtherProduct">{{ item.name }}</ProductCartTile>
                </TransitionGroup>
            </div>
            <button @click.prevent="validateCart" class="mobile-hide">Next Step</button> 
        </div>
        <div v-if="cart.cartItems.length == 0" class="empty-cart">
            <h3 class="condition-label">&nbsp;</h3>
            <div name="list" class="cart-list-wrapper">
                <ProductCartTile class="" :product="blankProduct()">&nbsp;</ProductCartTile>
                <ProductCartTile class="" :product="blankProduct()">&nbsp;</ProductCartTile>
                <ProductCartTile class="" :product="blankProduct()">&nbsp;</ProductCartTile>
            </div>
            <h3 class="condition-label">&nbsp;</h3>
            <div name="list" class="cart-list-wrapper">
                <ProductCartTile class="" :product="blankProduct()">&nbsp;</ProductCartTile>
            </div>
            <button @click.prevent="validateCart">Next Step</button>         
        </div>
        <button @click.prevent="validateCart" class="mobile-only fixed" v-if="cart.cartItems.length > 0">Next Step&nbsp;<i class="icon arrow right white"></i></button>         
    </div>
    <overlay-modal v-if="showModal" @close="showModal = false" ref="modal">
        <product-other-form :product="activeModalProduct" @update="toggleCartItem" @close="showModal = false"></product-other-form>
    </overlay-modal>
</template>



<script>
import { useCatalogStore, useCartStore } from '@/stores';
import { defineComponent } from 'vue';
import ProductCartTile from '@/components/ProductCartTile.vue';
import OverlayModal from './OverlayModal.vue';
import ProductOtherForm from './ProductOtherForm.vue';
import ProductCartRxAdd from './ProductCartRxAdd.vue';
import router from '@/router';

export default defineComponent({
    setup() {
        const catalog = useCatalogStore();
//        catalog.getCatalog();
        const cart = useCartStore();
        return { catalog, cart };
    }, data() { return {
        activeModalProduct: null,
        showModal: false,
    }},
    methods: {
        blankProduct() {
            let threshold = 0.4;
            return {
                format: "",
                image: "",
                name: "",
                times: {
                    morning: (Math.random() < threshold),
                    midday: (Math.random() < threshold),
                    evening: (Math.random() < threshold)
                }
            }
        },
        validateCart() {
            router.push('/review');
        },
        productCondition(filterCondition) {
            return this.cart.cartItems.filter((x) => { return x.activeConditions.includes(filterCondition); });
        },
        productFormat(filterFormat) {
            return this.cart.cartItems.filter((x) => { return x.format == filterFormat; });
        },
        editOtherProduct(otherProduct) {
            this.activeModalProduct = otherProduct;
            this.showModal = true;
            return null;
        },
        toggleCartItem(product) {
            this.cart.toggleProduct(product);
            if (this.cart.cartItems.length == 0) {
                document.querySelector('main').classList.remove('show-cart');
            }
            this.showModal = false;
        },
        toggleCartView() {
            document.querySelector('main').classList.toggle('show-cart');
            return;
        }
    },
    components: { ProductCartTile, ProductOtherForm, OverlayModal, ProductCartRxAdd }
});
</script>

