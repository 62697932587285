<template>
    <div class="product-catalog">
        <ProductCartRxAdd isMobile="true" />
        <ProductSelectionConditionCategory />
        <ProductSelectionCatalog />
    </div>
</template>

<style lang="scss">
.product-catalog {
    flex: 1 1 auto;
    margin: 0 30px;
    padding: 40px 35px 35px 40px;
}
</style>

<script>
import ProductSelectionConditionCategory from '@/components/ProductSelectionConditionCategory.vue';
import ProductSelectionCatalog from '@/components/ProductSelectionCatalog.vue'
import ProductCartRxAdd from '@/components/ProductCartRxAdd.vue'
export default {
  components: {
    ProductSelectionConditionCategory, ProductSelectionCatalog, ProductCartRxAdd
  }  
}
</script>