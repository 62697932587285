<template>
    <img :src="getLocalFile" ref="productImage">
</template>

<script>
export default {
    name: 'Base64ProductImage',
    props: [
        'src',
        'file'
    ],
    data() { return {
        base64Image: '',
        localPath: ''
    }},
    computed: {
        getLocalFile() {
            return 'productImages/' + this.file;
        }
    },
    methods: {
        toDataURL(src) {
            var img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = function() {
                var canvas = document.createElement('CANVAS');
                var ctx = canvas.getContext('2d');
                var dataURL;
                canvas.height = this.naturalHeight;
                canvas.width = this.naturalWidth;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL('image/png');
                console.log("DataURL: ", dataURL);
                this.$refs.productImage.src = dataURL;
            };
            img.src = src;
            if (img.complete || img.complete === undefined) {
                img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                img.src = src;
            }
        },
    },
    mounted() {
        let fn = this.file;
        this.localPath = 'productImages/'+fn;
        // this.toDataURL(this.localPath);

        // axios
        //     .get(props.product.image, {
        //         responseType: 'arraybuffer',
        //         headers: {
        //             'Content-Type': 'image/png'
        //         }
        //     })
        //     .then((resp) => {
        //         console.log("b64img",resp.data);
        //     })
        //     .catch((err) => {
        //         console.log("b64img",err);
        //     })
    }
}
</script>