<template>
    <Transition name="pii-flag">
        <div class="pii-wrap" v-if="visible">
            <div class="pii-flag">Please do not include any patient identifiable information in this text box.</div>
        </div>
    </Transition>
</template>

<script>
export default {
    props: {
        visible: Boolean
    }
}
</script>

<style lang="scss">

    .pii-flag {
        opacity: 0;
        position: absolute;
        text-align: center;
        right: 1px;
        margin-top: 1px;
        border-radius: 0 2px 0 5px;
        --ptarget: .5em;
        font-size: .75em;
        border: 1px solid var(--color-border-light-blue);
        // border-width: 0 0 1px 1px;
        border-top-color: var(--color-brand-blue);
        border-right-color: var(--color-brand-blue);
        background: rgba(249, 238, 182, 1);
        animation: piiflag 5s;
        animation-fill-mode: both;
        z-index: 2;

        &.quill {
            transform: translateY(20px);
            --ptarget: 9px .5em;
            border-radius: 0;
            border-color: var(--color-brand-blue) !important;
            width: 100%;
            right: 0;
            margin-top: 0;
        }
    }

    .pii-wrap {
        position: relative;
        transition: opacity .5s;
        opacity: 1;
    }
    @keyframes piiflag {
        0% {
            opacity: 0;
            padding: 0;
        }
        10% {
            opacity: 1;
            padding: var(--ptarget);
        }
        90% {
            opacity: 1;
            padding: var(--ptarget);
        }
        100% {
            opacity: 0;
            padding: 0;
        }
    }

    // .pii-flag-enter-active, .pii-flag-leave-active {
    // }
    // .pii-flag-enter-from,
    .pii-flag-leave-to {
        opacity: 0 !important;
    }


</style>