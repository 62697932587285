<template>
    <form class="product-other-form" @submit.prevent="onSubmit()">
        <div class="product-image">
            <Base64ProductImage :src="other.image" :file="other.fileName"></Base64ProductImage>
        </div>
        <label :bind="productLabel">{{ productLabel }}</label>
        <input v-if="otherItems.length == 0"
            id="otherName"
            v-model="other.otherName"
            :placeholder="productLabel + ' name'"
            :class="{ error : invalid }" />
        <simple-typeahead
            v-if="otherItems.length > 0"
            id="otherName"
            :placeholder="productLabel + ' name'"
            :items="otherItems"
            :defaultItem="other.otherName"
            @selectItem="updateOtherName"
            @onInput="updateOtherName"
            ></simple-typeahead>
        <div class="error" v-if="invalid">Please enter a {{ singularFormat.toLowerCase() }} name...</div>
        <div v-if="isRx">
            <label>Directions for Use</label>
            <TextareaPIIWarning :visible="activeField == 'other_instructions'"></TextareaPIIWarning>
            <textarea v-if="isRx" v-model="other.instructions" placeholder="Sample prescription instructions"
                @focus="activeField = 'other_instructions'" @blur="activeField = ''" ></textarea>
            <label>Applicable Skin Condition</label>
            <div class="condition-selector">
                <label v-for="(condition, index) in conditionList" :key="index">
                    <input name="condition" type="radio" v-model="activeCondition" :value="condition">{{ condition }}
                </label>
            </div>
        </div>
        <product-time-of-day class="time-of-day" @time="toggleTime" :times="other.times"><label>Time of Day</label></product-time-of-day>
        <div class="action-links">
            <button name="submit" v-if="createListing">Add to Regimen</button>
            <button name="submit" v-else>Update Regimen</button>
            <div class="link-format reset" @click.prevent="closeOverlay()">Cancel</div>
        </div>
    </form>
</template>

<script>
import ProductTimeOfDay from './ProductTimeOfDay.vue';
import Base64ProductImage from './Base64ProductImage.vue';
import { useTypeaheadStore } from '@/stores/typeahead.store';
import { useCatalogStore } from '@/stores';
import TextareaPIIWarning from './TextareaPIIWarning.vue';

export default {
    setup() {
        const catalog = useCatalogStore();
        return { catalog };
    },
    data() { return {
        activeField: '',
        other: {...this.product},
        editProduct: this.product,
        activeCondition: "Other",
        createListing: true,
        invalid: false
    }},
    mounted() {
        if (this.other.otherName) {
            this.createListing = false;
            this.activeCondition = this.other.activeConditions[0];
        } else {
            this.other.times = {
                morning: false,
                midday: false,
                evening: false
            };
        }
    },
    props: [
        'product'
    ],
    components: {
        ProductTimeOfDay, Base64ProductImage,
        TextareaPIIWarning
    },
    computed: {
        isRx() {
            return (this.other.format == 'Rx')
        },
        otherItems() {
            const typeahead = useTypeaheadStore();
            console.log("Alternate list:",this.other.format,typeahead.getOthers(this.other.format));
            return typeahead.getOthers(this.other.format);
        },
        productLabel() {
            if (this.isRx) {
                return "Prescription";
            }
            console.log("Other format",this.other);
            let format = this.singularFormat;
            return `Other ${format}`;
        },
        singularFormat() {
            if (this.isRx) {
                return "Prescription";
            }
            return this.other.format.replaceAll(/s(\/|$)/g,"$1");
        },
        conditionList() {
            return [...this.catalog.conditions.filter(cond=>!cond.hidden).map(cond=>cond.name)];
        }
    },
    methods: {
        onSubmit() {
            this.invalid = false;
            if (!this.other.otherName) {
                this.invalid = true;
                document.getElementById('otherName').focus();
                return false;
            }

            this.other.id += "|"+this.other.otherName;
            this.other.custom = true;
            this.other.activeConditions = [ this.activeCondition ];
            if (this.createListing) {
                this.$emit("update",this.other);
            } else {
                Object.keys(this.other).map((key)=>{ this.editProduct[key] = this.other[key] } );
                this.$emit("close");
            }
        },
        updateOtherName(value) {
            console.log("UPDATE:", value);
            this.other.otherName = (value.input) ? value.input : value;
        },
        toggleTime(target,value) {
            this.other.times[target] = value;
        },
        closeOverlay() {
            console.log("Close");
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss">
form {
    position: relative;
}

.product-other-form {
    min-width: 400px;
}

form.product-other-form {
    padding: 0;
    margin: 0;

    input#otherName,
    input, textarea {
        width: 100% !important;
        padding: .75em !important;
        box-sizing: border-box;
        border: 1px solid var(--color-brand-blue);

        &.error {
            border-color: var(--color-error);
        }

        &::placeholder {
            color: var(--color-placeholder);
        }
        &:-ms-input-placeholder { /* Edge 12 -18 */
            //color: var(--color-placeholder);
            color: red;
        }
    }

    label {
        display:block;
        font-weight: bold;
        padding-top: 1em;
    }

    .error {
        color: var(--color-error);
    }

    .product-image {
        position: relative;
        width: 100%;
        height: 150px;
        overflow: hidden;
        background: var(--color-product-image-bg);

        .icon {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 30px;
            height: 30px;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }

    .time-of-day {
        margin-bottom: .5em;
    }

    .condition-selector {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: .25em;
        margin-top: .75em;

        label {
            display: grid;
            grid-template-columns: 1.875em auto;
            align-items: center;
            gap: 0.5em;

            text-transform: none;
            font-weight: normal;
            color: #333;
            padding: 0;

            input[type="radio"] {
                position: relative;
                appearance: none;
                background-color: #fff;
                margin: 0;
                font: inherit;
                color: currentColor;
                width: 1.875em !important;
                height: 1.875em;
                border: 0.0625em solid var(--color-brand-blue);
                border-radius: 50%;

                &:checked::before {
                    content: "";
                    position: absolute;

                    background: url("@/assets/icon-checkmark.png");
                    background-size: contain;
                    background-position: top right;
                    background-repeat: no-repeat;

                    width: 1.5625em;
                    height: 1.75em;
                    top: -0.35em;
                    left: 0.5em;
                }
            }
        }
    }

    .action-links {
        margin-top: 1em;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
}


</style>