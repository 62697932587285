// export * from './Authenticate.vue';
// export * from './ProductRecommendation.vue';
// export * from './SiteFooter.vue';
// export * from './SiteHeader.vue';
// export * from './ProductCart.vue';

const requireModule = require.context(".", false, /\.vue$/); //extract vue files inside modules folder
const modules = {};

requireModule.keys().forEach(fileName => {

  const moduleName = fileName.replace(/(\.\/|\.vue)/g, ""); //

  modules[moduleName] = requireModule(fileName).default;
});

export default modules;