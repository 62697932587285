<template>
    <div class="overlay-outer" @close="closeOverlay()">
        <div class="overlay-background" @click="closeOverlay()"></div>
        <div class="overlay-inner">
            <div class="overlay-content">
                <slot></slot>
                <div class="close-overlay icon x-circle" @click="closeOverlay()"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    mounted() {
        // Modal is mounted
        document.addEventListener('keyup',this.escapeOverlay);
    },
    props: {
        product: null
    },
    methods: {
        escapeOverlay(e) {
            console.log("KeyUp",e);
            if(e.key === "Escape") {
                this.closeOverlay();
            }
        },
        closeOverlay() {
            document.removeEventListener('keyup',this.escapeOverlay);
            this.$emit('close');
        }
    }
})
</script>

<style lang="scss">
.overlay-outer {
    padding: 0 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 1000;
    background: none;
    
    * {
        box-sizing: border-box;
    }

    .overlay-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.8;
        transition: opacity .5s;

        background: var(--color-dark-blue);
    }

    .overlay-inner {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 2dvh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .overlay-content {
            position: relative;
            padding: 3em 3em 2em 3em;
            border-radius: .6325em;
            background: #ffffff;
            overflow: hidden;
            box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.16);
            z-index: 2;
            max-width: 96dvw;
            max-height: 96dvh;

            > *:first-child {
                overflow-y: scroll;
                height: 100%;
            }

            .close-overlay {
                position: absolute;
                top: 0;
                right: 0;
                margin: 1.125em;
                --color-hover-x: var(--color-dark-blue);
            }     

        }
    }
}
</style>