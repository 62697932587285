<template>
        <div v-if="cart.cartItems.length == 0">
            <p>There are no products currently in your regimen recommendation.</p>
            <p>Patient Regimen results will only be available to access for 30 days from creation.</p>
            <router-link to="/products">Return to the catalog</router-link>
        </div>

        <div v-if="cart.cartItems.length > 0">
            <retention-notice></retention-notice>
            <div v-for="condition in cart.activeConditions" v-bind:key="condition.id">
                <h3 class="condition-label">{{ condition.name }}</h3>
                <div name="list" class="review-list-wrapper" tag="div" :class="printColumnCount(condition.name)">
                    <ProductCartTile class="" v-for="item in productsByCondition(condition.name)" v-bind:key="item.id" :product="item"
                    finalPrint="true">{{ item.name }}</ProductCartTile>
                </div>
            </div>
        </div>

        <div class="text-panel single" v-if="showNotes || showConditions">
            <div class="condition-content" v-if="showNotes">
                <h3>Additional Notes</h3>
                <div v-text="cart.additionalNotes"></div>
            </div>
            <div v-for="condition in cart.activeConditionNotes" v-bind:key="condition.name" class="condition-content">
                <h3>About {{ condition.name }}</h3>
                <div v-html="condition.description" class="tip-text"></div>
                <h4>{{ tipName(condition) }} Skincare Tips</h4>
                <div v-html="condition.tips" class="tip-text"></div>
            </div>
        </div>
</template>

<script>
//import { useCatalogStore, useCartStore } from '@/stores';
import ProductCartTile from '@/components/ProductCartTile.vue';
import RetentionNotice from '@/components/RetentonNotice.vue';

export default {
    name: 'FinalRecoFormat',
    data() { return {
        emptyCart: false
    }},
    components: {
        ProductCartTile, RetentionNotice
    },
    computed: {
        cart() {
            console.log("COMPUTING CART", this.regimen);
            if (!this.regimen.cartItems) {
                console.log("NO REGIMEN!");
            }
            return this.regimen;
        },
        showNotes() {
            return this.cart.additionalNotes.replace(/<[^>]+>/g,'').length > 0;
        },
        showConditions() {
            return this.cart.activeConditionNotes.length > 0;
        }
    },
    props: [
        'regimen'
    ],
    methods: {
        productFormat(filterFormat) {
            return this.cart.cartItems.filter((x) => { return x.format == filterFormat; });
        },
        productsByCondition(filterCondition) {
            return this.cart.cartItems.filter((x) => { return x.activeConditions.includes(filterCondition); });
        },
        tipName(condition) {
            return condition.name.replace(/( skin|skin )/i,"");
        },
        printColumnCount(condition) {
            if (this.productsByCondition(condition).length < 3) {
                return "col-2";
            } else {
                return "col-3";
            }
        },
        productSortByFormat() {
            return this.cart.cartItems;
            // let newCart = [];
            // this.formats.forEach((filterFormat) => {
            //     newCart = [...newCart, ...this.cart.cartItems.filter((x) => { return x.format == filterFormat; })];
            // });
            // console.log("psbf",newCart);
            // return newCart;
        }
    }
}
</script>

<style lang="scss">

.text-panel.single {
    display: block;
    padding: 3em 40px 1.25em 40px;
}

.condition-content {
    transition: height .5s;
}


</style>