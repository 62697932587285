<template>
    <section>
        <ProductCart />
        <ProductSelection />
    </section>
</template>

<script>
import components from '@/components';
export default {
    components,
    mounted() {
        document.body.style.setProperty('--footer-offset','0px');
    }
}
</script>


<style lang="scss">
section {
    background: transparent;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    max-width: 1440px;
    margin: 0 auto;

    > * {
        background: var(--color-content-background);
    }
}
</style>
