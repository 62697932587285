<template>
    <form class="condition-editor">
        <h3>About {{ mycondition.name }}</h3>
        <TextareaPIIWarning class="quill" :visible="activeField == 'description'"></TextareaPIIWarning>
        <QuillEditor ref="desc" class="editor" v-model:content="mycondition.description" contentType="html"
            @focus="activeField = 'description'" @blur="activeField = ''" @textChange="checkEdited" ></QuillEditor>
        <label>{{ tipName }} Skincare Tips</label>
        <TextareaPIIWarning class="quill" :visible="activeField == 'tips'"></TextareaPIIWarning>
        <QuillEditor ref="tips" class="editor" v-model:content="mycondition.tips" contentType="html" style="min-height: 50px;"
            @focus="activeField = 'tips'" @blur="activeField = ''" @textChange="checkEdited" ></QuillEditor>
        <div class="split">
            <button @click.prevent="onSubmit">NEXT</button>
            <a href="#" @click.prevent="restoreCondition" class="reset-link" v-show="edited">Restore notes</a>
        </div>
    </form>
</template>

<script>
import TextareaPIIWarning from './TextareaPIIWarning.vue';
import { useCatalogStore } from '@/stores';
export default {
    name: 'ConditionEditor',
    props: [
        'condition'
    ],
    data() { return {
        activeField: '',
        mycondition: {...this.condition},
        edited: false,
        deferDetection: true
    }},
    mounted() {
        this.mycondition.tips = this.mycondition.tips.replace(/[\n\t]/g,"");
        this.edited = this.mycondition.edited;
        setTimeout(() => { this.deferDetection = false; },100);
    },
    computed: {
        tipName() {
            return this.mycondition.name.replace(/( skin|skin )/i,"");
        }
    },
    components: {
        TextareaPIIWarning
    },
    methods: {
        onSubmit() {
            this.mycondition.edited = this.edited;
            this.$emit("update",this.mycondition);
        },
        restoreCondition() {
            let restoreTo = this.catalog.conditions.find(item => item.name == this.mycondition.name);
            console.log(restoreTo, this.mycondition);
            this.mycondition.description = restoreTo.description;
            this.mycondition.tips = restoreTo.tips.replace(/[\n\t]/g,"");
            this.edited = false;
        },
        checkEdited() {
            if (!this.deferDetection) {
                this.edited = true;
            }
        }
    },  
    setup() {
        const catalog = useCatalogStore();
        return { catalog };
    },
}
</script>
<style scoped>
    div.split {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>

