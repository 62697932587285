<template>
    <section class="single-col regimen print-panel" ref="regimen">
        <table width="100%">
            <tbody>

                <div class="top-row">
                    <h1>My skincare regimen</h1>
                    <regimen-action-panel :lookupid="lookupID" v-if="!empty && !expired && loaded"></regimen-action-panel>
                </div>
                <div v-if="empty">
                    <p>We're sorry, but we were unable to find a regimen recommendation matching your requested ID ({{ lookupID }}).</p>
                    <p>This could be due to an improper QR Code scan or a network connection issue.  To rule out this event, please try scanning again later.</p>
                    <p>If that does not work, it is possible the record has expired or been deleted.</p>
                </div>
                <div v-if="expired">
                    <p>We're sorry, but regimen recommendations are only stored for 30 days.</p>
                    <p>The recommendation matching your requested ID ({{ lookupID }}) expired on {{ expiration }}.</p>
                </div>

                <final-reco-format :regimen="cart" v-if="!empty && !expired && loaded"></final-reco-format>

            </tbody>
            <tfoot>
                <regimen-print-footer></regimen-print-footer>
            </tfoot>
        </table>
    </section>
    <OverlayLoading v-if="!loaded">
        <p>Looking up your skincare regimen...</p>
    </OverlayLoading>

</template>

<script>
//import { useCatalogStore, useCartStore } from '@/stores';
import FinalRecoFormat from '@/components/FinalRecoFormat.vue';
import { appSheet } from '@/api/appSheet';
import RegimenActionPanel from '@/components/RegimenActionPanel.vue';
import RegimenPrintFooter from '@/components/RegimenPrintFooter.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';

export default {
    setup() {
        // const catalog = useCatalogStore();
        // catalog.getCatalog();
        // const cart = useCartStore();
        // cart.loadDemo();
        // return { catalog, cart };
        // console.log("DEMOCART",demoCart);
    },
    mounted() {
        // console.log("MOUNTED GetRegimen");
        this.lookupID = (this.id) ? this.id.toUpperCase() : 'DEMO';
        let request = {
            "Properties": {
                "Selector": `Filter('Recommendation',[LookupID] = ${this.lookupID})`
            }
        };
        console.log("SUBMITTING RECORD",request);
        appSheet.request("Recommendation",request)
            .then((response)=>{
                console.log("AppSheet Response: ",response)
                if (response.data.length > 0) {
                    let jsonmerge = response.data.map((row)=>row.JSON).join('');
                    if (jsonmerge.length > 0) {
                        this.cart = JSON.parse(jsonmerge);
                        console.log(`Merged ${response.data.length} chunks of data.`,this.cart);
                        if (!this.cart.activeConditionNotes) {
                            // https://stackoverflow.com/questions/4647817/javascript-object-rename-key
                            // Version issue -- activeConditions is now activeConditionNotes
                            this.cart.activeConditionNotes = this.cart.activeConditions;
                            console.log("Renamed activeConditions to activeConditionNotes",this.cart);
                        }
                    } else {
                        this.expiration = new Date(response.data[0].ExpirationDate).toDateString();
                        this.expired = true;
                    }
                } else {
                    this.empty = true;
                }
                this.loaded = true;
            })
            .catch((error)=>{
                console.log("AppSheet Error: ",error);
            });
    },
    props: [
        'id'
    ],
    data() { return {
        showModal: false,
        additionalNotes: "Additional notes here",
        conditionEdit: [],
        cart: {},
        loaded: false,
        lookupID: '',
        empty: false,
        expired: false,
        expiration: ''
    }},
    components: {
        FinalRecoFormat, RegimenActionPanel, RegimenPrintFooter, OverlayLoading
    },
}
</script>


