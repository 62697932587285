<template>
    <div class="product-selection-catalog" v-for="format in catalog.formats" v-bind:key="format">
        <h3>{{ format }}</h3>
        <div class="product-list-wrapper">
            <ProductTile :selected="cart.inCart(item)" v-for="item in productFormat(format)" v-bind:key="item.id" :product="item" @click="toggleCartItem(item)"></ProductTile>
        </div>
    </div>
    <button class="mobile-only fixed cart-toggle" v-if="true || cart.cartItems.length > 0" @click=" toggleCartView ">
        <div>
            Patient Regimen&nbsp;<i class="icon arrow right white"></i> 
        </div>
        <div class="product-count">{{ cart.cartItems.length }} item{{ pluralCart }}</div>
    </button>
    <overlay-modal v-if="showModal" @close="showModal = false" ref="modal">
        <product-other-form :product="activeProduct" @update="toggleCartItem" @close="showModal = false"></product-other-form>
    </overlay-modal>
    <OverlayLoading v-if="loading">
        <p>Refreshing product list...</p>
    </OverlayLoading>
</template>

<script>
import ProductTile from '@/components/ProductTile.vue';
import { useCatalogStore, useCartStore } from '@/stores';
import { defineComponent } from 'vue';
import OverlayModal from './OverlayModal.vue';
import OverlayLoading from './OverlayLoading.vue';
import ProductOtherForm from './ProductOtherForm.vue';

export default defineComponent({
    setup() {
        const catalog = useCatalogStore();
        const cart = useCartStore();
        return { catalog,cart };
    },
    mounted() {
        this.loading = true;
        this.checkCatalogLoading();
    },
    components: {
        ProductTile, OverlayModal, ProductOtherForm, OverlayLoading
    },
    computed: {
        pluralCart() {
            return ( this.cart.cartItems.length != 1 ) ? "s" : "";
        }
    },
    data() { return {
        showModal: false,
        activeProduct: null,
        loading: false
    }},
    methods: {
        checkCatalogLoading () {
            if (this.catalog.products.length > 0) {
                this.loading = false;
                return;
            }
            setTimeout(()=>{
                this.checkCatalogLoading();
            },250);
        },
        productFormat (filterFormat) {
            return this.catalog.products.filter((x)=>{return x.format == filterFormat});
        },
        toggleCartItem (product) {
            if (product.other && !product.custom) {
                this.activeProduct = product;
                this.showModal = true;
            } else {
                if (!product.activeConditions) {
                    product.activeConditions = [];
                }
                product.activeConditions.push(this.catalog.activeCondition);
                this.cart.toggleProduct(product);
                console.log("TOGGLE CART ITEM",product);
                this.showModal = false;
            }
        },
        toggleCartView() {
            document.querySelector('main').classList.toggle('show-cart');
            return;
        }
    }
})
</script>


