<template>
    <div>
      <div v-if="false" style="position: relative; width: 75%; max-width: 600px; min-width: 450px; margin: 10px auto;"><editor api-key="kk9ay1bdgl10yrzusp6o51ou266tu239v4weqi8vpt9vb90a" :init="{
        plugins: 'lists',
        toolbar: 'undo redo | bold italic underline | removeformat | alignleft aligncenter alignright | bullist outdent indent',
        menubar: false,
        statusbar: false,
        width: '100%'
      }" /></div>
      <form @submit.prevent="onSubmit">
        <label v-if="false">First Name
            <input :class="{ invalid: errorFields.includes('firstname') }" id="firstname" type="text" v-model="firstname" placeholder="First Name">
            <div v-if="errorFields.includes('firstname')" class="error">First name is a required field</div>
        </label>
        <label>NPI Number
            <input :class="{ invalid: errorFields.includes('npinumber') }" id="npinumber" type="text" inputmode="numeric" pattern="[0-9]{10}" v-model="npinumber" placeholder="0000000000">
        </label>
        <div v-if="errorMsg" class="error">{{ errorMsg }}</div>
        <p class="context">Your NPI number validates that you are a healthcare professional.</p>
        <label class="checkmark">
            <input type="checkbox" v-model="rememberme" placeholder="NPI Number">
            <span></span> Remember me
        </label>
        <div class="login">
            <button type="submit">Log In</button>
            <a style="display:none;" href="#" @click="clearForm">Clear Form</a>
        </div>
      </form>
<!-- <div>1700299930 - Quinn</div> -->
    </div>
    <OverlayLoading v-if="loading">
        <p>Validating your NPI...</p>
    </OverlayLoading>
  </template>


<script>
import Editor from '@tinymce/tinymce-vue';
import { useAuthStore } from '@/stores/';
import OverlayLoading from '@/components/OverlayLoading.vue';

export default {
    name: 'NPIValidator',
    data() {
      return {
        firstname: '',
        npinumber: '',
        rememberme: false,
        errorFields: [],
        errorMsg: '',
        loading: false
      }
    },
    setup() {
    },
    mounted() {
        this.errorFields = [];
        if (window.localStorage.getItem('npiref')) {
            let user = JSON.parse(window.localStorage.getItem('npiref'));
            if (new Date(user.expiration).getTime() > Date.now()) {
                this.firstname = user.firstname;
                this.npinumber = user.npi;
                this.rememberme = true;
            }
        }
            let field = document.querySelector("#npinumber");
            document.addEventListener('keyup',(e)=>{
                if ((e.key == 'u' || e.key == 'U') && e.ctrlKey && e.shiftKey) {
                    console.log("MAGIC COMBO!");
                    field.value = '1982014924';
                }
            });
    },
    components: {
        editor: Editor,
        OverlayLoading
    },
    methods: {
        clearForm() {
            this.firstname = "";
            this.npinumber = "";
            this.errorMsg = "";
            this.errorFields = [];
            this.rememberme = false;
        },
        async onSubmit() {
            // TODO: Submit the login form
            this.errorMsg = '';
            this.errorFields = '';
            this.loading = true;

            const authStore = useAuthStore();
            var result = await authStore.validateNPI_NIH(this.firstname, this.npinumber, this.rememberme, 'products');
            if (result.status == 'error') {
                this.errorFields = result.fields;
            } else if (result.status != 'success') {
                this.errorFields = result.fields;
                this.errorMsg = result.message;
            }
            this.loading = false;
//            console.log(result);

            return false;
        }

    }
  }
</script>
  



<style lang="scss">
    .context {
        color: var(--color-dark-blue);
        font-size: .875em;
        font-style: italic;
        font-weight: 300;
    }
    form {
        margin-top: 3em;
    }
    .error {
        font-size: .875em;
        font-weight: 600;
        color: var(--color-error);
        text-transform: none;
        padding-top: .5em;
    }
    label {
        border: none;
        padding: .5em 0 .5em 0;
        margin: 0;
        display: block;
        text-transform: uppercase;
        color: var(--color-dark-blue);
        font-weight: bold;
        padding: .75em 0 .25em 0;



        &.checkmark {
            font-size: 0.875em;
            text-transform: initial;
            font-weight: normal;
            margin: 0;

            display: flex;
            flex-direction: row;
            flex-wrap: none;
            align-items: center;

            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &.error {
                    border-color: var(--color-error);
                    font-weight: normal;
                }
            }
            /* Create a custom checkbox */
            span {
                position: relative;
                height: 1em;
                width: 1em;
                margin-right: .5em;
                border: 1px solid var(--color-dark-blue);
            }
            /* On mouse-over, add a grey background color */
            &:hover input ~ span {
                background-color: #e0e0e0;
            }

            /* When the checkbox is checked, add a blue background */
            input:checked ~ span {
                background-color: var(--color-dark-blue);
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            span:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the checkmark when checked */
            input:checked ~ span:after {
                display: block;
            }

            /* Style the checkmark/indicator */
            span:after {
                left: 30%;
                top: 8%;
                width: 25%;
                height: 50%;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        input[type=text],
        input[type=number] {
            box-sizing: border-box;
            width: 100%;
            padding: .75em;
            margin-top: .25em;

            &.invalid {
                border: 1px solid var(--color-error);
            }

            &::placeholder {
                font-size: .875em;
                font-style: italic;
                color: var(--color-placeholder);
                opacity: 1; /* Firefox */
            }
            &::-ms-input-placeholder {/* Edge 12 -18 */
                font-size: .875em;
                font-style: italic;
                color: var(--color-placeholder);
            }
        }
    }
    .login {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 1em;
    }
</style>