// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/icon-newpatient.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/icon-reset.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/icon-logout.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "header[data-v-4617ed15]{position:relative;padding:0;margin:0;width:100%;overflow:visible;z-index:100}@media screen and (max-width:550px){header div.container[data-v-4617ed15]{padding:10px 20px!important}header div.container>a[data-v-4617ed15]{max-width:50%}header a[data-v-4617ed15]{position:relative}header div.header_actions[data-v-4617ed15]{display:flex;flex-direction:column;align-items:flex-end}header div.header_actions a[data-v-4617ed15]{padding:.4em 0;white-space:nowrap}}@media screen and (max-width:435px){header div.container>a[data-v-4617ed15]{flex:1 1 auto}header img.siteLogo[data-v-4617ed15]{position:relative;width:100%!important}}header[data-v-4617ed15]:before{position:absolute;z-index:100;width:100%;height:100%;margin-left:0;content:\" \";box-shadow:0 0 1em #000}header *[data-v-4617ed15]{z-index:110}header .panel[data-v-4617ed15]{background:#fff;padding:0;width:100%}header div.container[data-v-4617ed15]{background:#fff;padding:30px 40px;margin:0 auto;display:flex;font-size:.9375em;font-weight:700;justify-content:space-between;align-items:center;max-width:1440px}header div.container div.header_actions a[data-v-4617ed15]{margin-left:50px}header div.container div.header_actions a[data-v-4617ed15]:before{content:\" \";position:absolute;margin-left:-1.25em;margin-top:.15em;width:1em;height:1em;background-position:0;background-repeat:no-repeat;background-size:contain}header div.container div.header_actions a.newpatient[data-v-4617ed15]:before{width:15px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}header div.container div.header_actions a.reset[data-v-4617ed15]:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}header div.container div.header_actions a.logout[data-v-4617ed15]:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}header img.siteLogo[data-v-4617ed15]{width:185px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
