import { defineStore } from 'pinia';
import { appSheet } from '@/api/appSheet';
// import catalog from './catalog.json';
// import conditions from './conditions.json';

const PRODUCT_EXPIRATION_DAYS = 2;
const CONDITION_EXPIRATION_DAYS = 2;

export const useCatalogStore = defineStore({
    id: 'catalog',
    state: () => ({
        myconditions: [],
        myformats: [],
        myproducts: [],
        loading: false,
        activeCondition: "None"
    }),
    getters: {
        conditions: (state)=>{ return state.myconditions; },
        formats: (state)=>{ return state.myformats; },
        products: (state)=>{ return state.myproducts.filter((x)=>{ return x.conditions.includes(state.activeCondition); }); },
        allProducts: (state)=>{ return state.myproducts; },
        rxProduct: (state)=>{ return state.myproducts.find(obj => { return obj.format == "Rx" })}
    },
    actions: {
        async getCatalog() {
            let catalog = window.localStorage.getItem('catalog');
            if (catalog && catalog.length > 0) {
                catalog = JSON.parse(catalog);
                if (catalog && new Date(catalog.expiration).getTime() > Date.now()) {
                    console.log("Loading catalog from localStorage",catalog);
                    this.myproducts = catalog.products;
                    this.myformats = catalog.formats;
                } else {
                    console.log("Catalog exists in localStorage, but has expired.");
                }
            }
            let conditions = window.localStorage.getItem('cats');
            if (conditions && conditions.length>0) {
                conditions = JSON.parse(conditions);
                if (conditions && new Date(conditions.expiration).getTime() > Date.now()) {
                    this.myconditions = conditions.conditions;
                    this.activeCondition = this.myconditions[0].name;
                }
            }

            // this.myproducts = catalog.products;
            // this.myformats = catalog.formats;
            // this.myconditions = conditions.conditions;
            // this.activeCondition = this.myconditions[0].name;

            if (this.myconditions.length == 0) {
                this.refreshConditions();
            }
            if (this.myproducts.length == 0 && !this.loading) {
                this.refreshCatalog();
            }

        },
        async refreshConditions() {
            let results = await appSheet.request('Condition',{})
                .then((response)=>{
                    return response.data;
                })
                console.log("CATEGORIES",results);
                this.myconditions = results.map((x)=>{
                return {
                    name:   x.TabLabel,
                    description: x.DefaultDescription,
                    tips:   x.DefaultTips,
                    order:  x.order,
                    hidden: x.hidden,
                    id:     x['Row ID']
                }
            });

            window.localStorage.setItem('cats',JSON.stringify({
                'conditions': this.myconditions,
                'expiration': new Date((new Date()).setDate((new Date()).getDate() + CONDITION_EXPIRATION_DAYS))
            }));

            this.activeCondition = this.myconditions[0].name;
        },
        async refreshCatalog() {
            this.loading = true;
            let products = Array();
            let formats = Array();

            let results = await appSheet.request('Products',{})
                .then((response)=>{
                    return response.data;
                });
            console.log("CATALOG",results);
            if (!results) { this.loading = false; return null; }
            let index = 0;
            while (results.length > 0) {
                let item = results.shift();
                let product = {
                    id:     item['Row ID'],
                    name:   item.ProductName,
                    legitname: item.ProductName.replace(/([^ ]+ [^ ]+$)/,"<span class=\"nobr\">$1</span>"),
                    image:  item.ImageURL,
                    fileName: item.FileName,
                    format: item.ProductFormatLabel,
                    order:  (item.Order == "") ? index : item.Order,
                    other:  (item.ProductName.match(/^other/i)) ? true : false,
                    conditions: item.ProductConditions.split(",").map((x) => x.trim()),
                    times: {
                        morning:    false,
                        midday:     false,
                        evening:    false
                    }
                };
                if (!item.HideFormat && !formats.includes(product.format)) {
                    formats.push(product.format);
                }
                products.push(product);
                index++;
            }
            formats.sort();
            products.sort((a,b) => a.order - b.order || a.name.localeCompare(b.name));

            this.myproducts = products;
            this.myformats = formats;
            this.loading = false;

            window.localStorage.setItem('catalog',JSON.stringify({
                'products': this.myproducts,
                'formats': this.myformats,
                'expiration': new Date((new Date()).setDate((new Date()).getDate() + PRODUCT_EXPIRATION_DAYS))
            }));

            console.log("Formats:",this.myformats);
            console.log("Products:",this.myproducts);
        }
    }
});