<template>
    <p class="retention-notice">Patients will have 30 days to digitally access their regimen results.</p>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>
<style lang="scss">
.retention-notice {
    font-style: italic;
    text-align: left;
}
</style>