<template>
    <nav class="condition-selector">
        <h4>
            <img src="@/assets/icon-filter.svg" class="filter-icon">
            <div class="mobile-only inline">FILTER PRODUCTS</div>
        </h4>
        <ul>
            <li :class="{ selected: (condition.name == catalog.activeCondition) }"
                @click="setCondition(condition.name)"
                v-for="condition in activeConditions" v-bind:key="condition.id">{{ condition.name }}</li>
        </ul>
    </nav>
</template>

<script>
import { useCatalogStore } from '@/stores';
import { defineComponent } from 'vue';

export default defineComponent({
    setup() {
        const catalog = useCatalogStore();
//        catalog.getCatalog();
        return { catalog };
    },
    computed: {
        activeConditions() {
            return this.catalog.conditions.filter(c=>!c.hidden);
        }
    },
    methods: {
        setCondition(condition) {
            this.catalog.activeCondition = condition;
        }
    }
})
/*

export default {
    data() {
        return {
            categories: [
                'Dry & Atopic Skin',
                'Psoriasis',
                'Acne',
                'Wound Care',
                'Babies & Children',
                'General Daily Skincare'
            ],
            selectedCondition: 0
        }
    },
    methods: {
        setCondition(index) {
            this.selectedCondition = index;
        }
    }
}
*/
</script>

<style lang="scss">
    nav.condition-selector {
        display: flex;
        align-items: flex-start;
        margin: 0;
        padding: 0 0 2em 0;
        border-bottom: 1px solid var(--color-border-grey);
        font-size: .9375em;

        h4 {
            margin: 0 0 .25em 0;
        }

        @media screen and (max-width: 900px) {
            flex-direction: column;
            h4 {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: .75em;
                .filter-icon {
                    margin: 0 .5em 0 0;
                }
                .mobile-only {
                    display: inline;
                }
            }
        }

        .filter-icon {
            width: 20px;
            margin: .75em 1em .5em 0;
        }

        @media screen and (max-width: 1024px) {
            font-size: .8em;
        }

        ul {
            flex: 1 1 auto;
            display: grid;
            grid-template-columns: repeat(6, auto);
            grid-gap: .75em;
            width: 100%;
            list-style: none;
            padding: 0;
            margin: 0;

            @media screen and (max-width: 1200px) {
                grid-template-columns: repeat(3, auto);
            }
            @media screen and (max-width: 900px) {
                grid-template-columns: repeat(2, auto);
            }

            li {
                position: relative;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;

                padding: .75em 0.25em;
                text-align: center;
                color: var(--color-brand-blue);
                cursor: pointer;


                &::before {
                    content: ' ';
                    position: absolute;
                    box-sizing: border-box;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 1px solid var(--color-brand-blue);
                    border-radius: .5em;
                }

                &.selected {
                    font-weight: bold;
                    color: var(--color-dark-blue);
                    &::before {
                        
                        border: 2px solid var(--color-dark-blue);
                    }
                }
            }
        }
    }
</style>
