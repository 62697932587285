<template>
    <div class="product-tile" :class="{ selected }">
        <div class="product-image">
            <img :src="product.image">
            <i class="icon selected" v-if="selected"></i>
        </div>
        <div class="product-name">{{product.name}}</div>
    </div>
</template>

<script>
export default {
    props: [
        'product','selected'
    ]
}
</script>

<style lang="scss">
.product-tile {

    border: 1px solid var(--color-border-light-blue);
    border-radius: .5em;
    position: relative;
    overflow: hidden;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    z-index: 1;

    &::after {
        box-sizing: border-box;
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        border-radius: calc(.5em - 1px);
        z-index: 2;
    }

    &:hover {
        &::after {
            cursor: pointer;
            box-shadow: inset 0 0 5px var(--color-border-light-blue);
        }
    }

    &.selected {
        border: 1px solid var(--color-dark-blue);
        border: 1px solid transparent;

        .product-image {
            background: var(--color-product-image-bg-selected);
        }
        &::after {
            border: 2px solid var(--color-dark-blue);
        }
    }

    .product-image {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        overflow: hidden;
        background: var(--color-product-image-bg);
        //min-height: 80px;

        .icon {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 35%;
            height: 0;
            padding-bottom: 9%;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }

    .product-name {
        flex: 1 1 auto;
        --padding: 0.5em 0.75em;
        min-height: 2em;
        color: var(--color-brand-blue);
        font-size: .9375em;
        margin: 0;
        padding: var(--padding);
        display: flex;
        align-items: center;

        @media screen and (max-width: 900px) {
            font-size: .875em;
            padding: .175em .5em;
        }
    }
}
</style>