import { defineStore } from 'pinia';
import { toRaw } from 'vue';
// import { appSheet } from '@/api/appSheet';

import { useCatalogStore } from './catalog.store';

export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        additionalNotes: "",
        cartItems: [],
        conditions: [],
        lookupID: "",
        submitted: false
    }),
    persist: {
        storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
    },
    getters: {
        getLookupID: (state) => {
            console.log("getLookupID: "+state.lookupID);
            return state.lookupID;
        },
        formatCount: (state) => {
            return (format)=>state.cartItems.reduce((acc, cur) => cur.format === format ? ++acc : acc, 0);
        },
        inCart: (state) => {
            return (product)=>state.cartItems.find(cur => cur.id === product.id);
        },
        conditionList: (state)=>{
            let clist = state.cartItems.map((item)=>item.activeConditions[0]);
            // remove duplicates and return
            return clist.filter((value,index)=>clist.indexOf(value) === index);
        },
        activeConditions: (state)=>{
            let catalog = useCatalogStore();
            return catalog.conditions.map(x=>{ return { name:x.name }}).filter(condition=>state.conditionList.includes(condition.name));
        },
        productConditionNotes: (state)=>{
            return state.conditions.filter(condition=>(!condition.hidden && state.conditionList.includes(condition.name)));
        },
        activeConditionNotes: (state)=>{
            return state.conditions.filter(condition=>(!condition.hidden && condition.active && state.conditionList.includes(condition.name)));
        },
        getConditions: (state)=>{
            // unused function
            if (state.conditions.length == 0) {
                let clist = state.cartItems.map((item)=>item.activeConditions);
                let catalogStore = useCatalogStore();
                state.conditions = catalogStore.conditions.map((item)=>{return {...item, ...{active:clist.includes(item.name)}}});
            }
            console.log('availableConditions',state.conditions);
            return state.conditions;
        },
    },
    actions: {
        submit() {
            this.submitted = true;
        },
        productsByCondition(filterCondition) {
            return this.cart.cartItems.filter((x) => { return x.activeConditions.includes(filterCondition); });
        },
        resetCart() {
            // Force a refresh on the catalog just in case.
            let catalogStore = useCatalogStore();
            catalogStore.getCatalog();
            setTimeout(()=>{
                /**  
                 * If someone is resetting the form in mobile, they often don't realize that the "cart"
                 * style is still in place and they see the (now) empty cart graphics.  Putting this
                 * inside a timeOut to quickly verify that the cart is empty (since it isn't immediately
                 * visible in mobile) before sliding away.
                */
                document.querySelector('main').classList.remove('show-cart');
            },500);

            this.additionalNotes = "";
            this.cartItems = [];
            this.conditions = [];
            this.lookupID = "";
            this.submitted = false;
        },
        refreshLookup() {
            // https://dev.to/oyetoket/fastest-way-to-generate-random-strings-in-javascript-2k5a
            const generateRandomString = function(length=7){
                return Math.random().toString(20).substr(2, length)
            }

            this.submitted = false;
            this.lookupID = generateRandomString().toUpperCase();
            console.log("REFRESH LOOKUP ID: "+this.lookupID);
        },
        updateCondition(name, active = true) {
            console.log("updateCondition: "+name,this.conditions)
            let toUpdate = this.conditions.find(item => item.name == name);
            toUpdate.active = active;
            return;
        },
        toggleCondition(name) {
            let toUpdate = this.conditions.find(item => item.name == name);
            console.log("toggleCondition",name,toUpdate);
            toUpdate.active = !toUpdate.active;
            return;
        },
        activateCurrentCondition(myActiveCondition = null) {
            console.log("activateCurrentCondition for "+myActiveCondition);
            let catalogStore = useCatalogStore();
            if (myActiveCondition && !this.conditions.find(condition=>condition.name == myActiveCondition)) {
                console.log(`'${myActiveCondition}' not found in cart conditions list. Adding it.`);
                this.conditions.push(catalogStore.conditions.find(condition=>condition.name == myActiveCondition));
            } else if (!this.conditions.find(condition=>condition.name == catalogStore.activeCondition)) {
                console.log(`'${catalogStore.activeCondition}' not found in cart conditions list. Adding it.`);
                this.conditions.push(catalogStore.conditions.find(condition=>condition.name == catalogStore.activeCondition));
                myActiveCondition = catalogStore.activeCondition;
            } else {
                console.log('Nothing added to this.conditions:',this.conditions);
            }
            if (myActiveCondition)
                this.updateCondition(myActiveCondition);
                    },
        /**
         * Deleting this function -- shifted to toggleProduct
         */
        /*
        addProduct(product) {
            console.log("addProduct",product);
            this.refreshLookup();
            let cart = [...this.cartItems].filter((line)=>{
                return line.id !== product.id;
            });
            if (!product.times) {
                product.times = {
                    morning: false,
                    midday: false,
                    evening: false
                };
            }
            cart.unshift(product);
            cart.sort((a,b) => { return (a.order > b.order) ? 1 : -1; });
            this.cartItems = cart;
            this.activateCurrentCondition();
            console.log("AddProduct:",this.cartItems,this.conditions);
        },
        */
        toggleProduct(product) {
            console.log("Toggle cart product",product);
            let cart = [...this.cartItems].filter((line)=>{
                return line.id !== product.id;
            });
            if (cart.length == this.cartItems.length) {
                // No item in the cart, so add one
                if (product.activeConditions.length > 1) {
                    // if the product has only one "activeConditions", then leave it be
                    let catalogStore = useCatalogStore();
                    product.activeConditions = [catalogStore.activeCondition];
                }
                if (!product.times) {
                    product.times = {
                        morning: false,
                        midday: false,
                        evening: false
                    };
                }
                if (!product['order']) {
                    product.order = 0;
                }
                cart.unshift(product);
                cart.sort((a,b) => { return (a.order > b.order) ? 1 : -1; });
            }
            this.activateCurrentCondition(product.activeConditions[0]);
            //this.refreshConditions();
            console.log("toggle newCART",cart);
            this.cartItems = cart;

            this.refreshLookup();
            console.log("toggleProduct:",this.cartItems,this.activeConditions);
        },
        updateConditionText(condition) {
            this.refreshLookup();
            this.conditions = this.conditions.map((item)=> {
                return (item.id == condition.id) ? condition : item;
            });
            console.log(this.conditions);
        },
        removeProduct(product) {
            this.refreshLookup();
            this.cartItems = [...this.cartItems].filter((line)=>{
                return line.id !== product.id;
            });
            return;
        },
        slimCart() {
            let exp = {
                additionalNotes: toRaw(this.additionalNotes),
                cartItems: toRaw(this.cartItems),
                activeConditions: toRaw(this.activeConditions),
                activeConditionNotes: toRaw(this.activeConditionNotes)

            };
            exp.cartItems.forEach((item)=>{
                delete item.conditions;
                delete item.legitname;
            });
            return exp;
        },
        jsonCart() {
            return JSON.stringify(this.slimCart());
        }
    }
});

