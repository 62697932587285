<template>
    <div class="print-footer">
        <img class="siteLogo" src="@/assets/theReco.png" alt="theReco" />
        <img src="@/assets/powered-by-loreal-dermatological-beauty-black.png" alt="Powered by L'Oreal Dermatological Beauty" class="poweredby" />
    </div>
</template>

<style lang="scss">
.print-footer {
    height: 1in;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    padding: 14px 0;
    border-bottom: 10px solid var(--color-dark-blue);

    h4 {
        font-weight: normal;
        font-size: 16px;
        padding-top: 6px;
        text-align: right;
        flex: 1 1 auto;
        margin-right: 1em;
    }
    img {
        width: 2in !important;
        height: auto !important;
        &.poweredby {
            margin-top: .08in;
            width: 3in !important;
        }
    }
}
</style>