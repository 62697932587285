import axios from "axios";

export const appSheet = {
    /*
        DEV APP ID
            ab86d281-fba1-4961-99fb-0c3f38bc873b
        DEV APP KEY
            V2-ZZWzo-0xR9N-FpFoe-yyLCw-loNGn-r6tdn-uiSvL-uaKD1

        STAGING APP ID
            f02da203-d46a-45c5-a5ec-6cfb1512c026
        STAGING APP KEY
            V2-kNiXJ-c31r3-2qCnA-US9j3-LSAox-pZ7nG-dOWpK-G7AD8
    */
    /** 
     * Minor obfuscation -- since the AppID and AppKey will be stored in the compiled code, we'll use btoa() and atob() 
     * to base64 encode the string for each to make it a little harder for people to sniff out using a
     * simple search through the compiled code.
    */
    request: async (tableName = "Catalog", requestPackage = {}) => {
        let env = {
            'd':{
//                id: "ab86d281-fba1-4961-99fb-0c3f38bc873b",
                i: atob('YWI4NmQyODEtZmJhMS00OTYxLTk5ZmItMGMzZjM4YmM4NzNi'),
//                key: "V2-ZZWzo-0xR9N-FpFoe-yyLCw-loNGn-r6tdn-uiSvL-uaKD1"
                k: atob('VjItWlpXem8tMHhSOU4tRnBGb2UteXlMQ3ctbG9OR24tcjZ0ZG4tdWlTdkwtdWFLRDE=')
            },
            'p':{   // PRODUCTION
//                id: "f02da203-d46a-45c5-a5ec-6cfb1512c026",
                i: atob('ZjAyZGEyMDMtZDQ2YS00NWM1LWE1ZWMtNmNmYjE1MTJjMDI2'),
//                key: "V2-kNiXJ-c31r3-2qCnA-US9j3-LSAox-pZ7nG-dOWpK-G7AD8"
                k: atob('VjIta05pWEotYzMxcjMtMnFDbkEtVVM5ajMtTFNBb3gtcFo3bkctZE9XcEstRzdBRDg=')
            },
            's':{  // STAGING
                // let appSheetAppId = 'c1ac2b4e-e9e8-465a-b186-070515332099';
                i: atob('YzFhYzJiNGUtZTllOC00NjVhLWIxODYtMDcwNTE1MzMyMDk5'),
                // let appSheetKey = 'V2-VnVlL-cL60A-oy0NO-LPXN6-HQuAh-gLhWU-0mD1y-8wjc7';
                k: atob('VjItVm5WbEwtY0w2MEEtb3kwTk8tTFBYTjYtSFF1QWgtZ0xoV1UtMG1EMXktOHdqYzc=')
            }
        }

        // let appSheetAppId = 'c1ac2b4e-e9e8-465a-b186-070515332099';
        let appSheetAppId = env.p.i;
        // let appSheetKey = 'V2-VnVlL-cL60A-oy0NO-LPXN6-HQuAh-gLhWU-0mD1y-8wjc7';
        let appSheetKey = env.p.k;

        if (window.location.hostname == 'localhost'
            // || /cleverhats\.com$/.test(window.location.hostname)
            ) {
                // We're in a dev environment
                console.log("DEV ENVIRONMENT AppSheet IN USE");
                appSheetAppId = env.d.i;
                appSheetKey = env.d.k;
        }

        let apiURL = `https://api.appsheet.com/api/v2/apps/${appSheetAppId}/tables/${tableName}/Action`;

    //        https://api.appsheet.com/api/v2/apps/b8b4fbb2-6156-47ec-8e2a-4a0668e0103a/Product_Images/PiI9xLyWEJuRwaR4lb9aU7.ProductImage.165944.png

        let defaultRequestPackage = {
            'Action':'Find',
            "Properties": {
                "Locale": "en-US"
            },
            'Rows':[]
        };
        requestPackage = { ...defaultRequestPackage, ...requestPackage };

        let response = {};
//        console.log('appSheet.api',{ appSheetAppId, appSheetKey, apiURL, requestPackage });
        try {
            console.log('appSheet.api: prePost', requestPackage);
            response = await axios.post(apiURL,requestPackage,{
                    headers:{
                        'ApplicationAccessKey': appSheetKey
                    }
                });
        } catch (error) {
            console.log("appSheet.api",error);
            console.log(axios);
            return error;
        }
        // console.log('appSheet API - post request completed');
        return response;
    },
    chunkLargeString: (data) => {
        // appSheet requests have a character cap of 2000 characters per cell
        // https://support.google.com/appsheet/answer/12653576?hl=en
        return data.match(/.{1,1999}/g);
    }
};