<template>
    <div class="cart-row" :class="{'print-layout':finalPrint}">
        <div class="cart-image" :class="{ blank: !product.image}">
            <img v-if="!finalPrint" :src="product.image">
            <Base64ProductImage v-else :src="product.image" :file="product.fileName"></Base64ProductImage>
        </div>
        <div class="cart-card">
            <div class="format-row">
                <div class="format-flag"><div v-html="singularFormat"></div><img src="@/assets/flag-angle.png" class="flag-angle"></div>
                <div class="remove icon x-circle" @click="$emit('removeRow',product)" v-if="!this.finalPrint"></div>
            </div>
            <div class="cart-text-wrapper">
                <div class="edit" v-if="isEditable" @click="$emit('editProduct',product)">Edit</div>
                <div class="cart-name">{{ productName }}</div>
                <div class="product-instruction" v-if="product.instructions">{{ product.instructions }}</div>
            </div>
            <product-time-of-day v-if="!this.finalPrint" @time="toggleTime" :times="product.times"></product-time-of-day>
            <div class="times-of-day" v-if="this.finalPrint && timeString.length > 0">
                <strong>Time of Day:</strong> <span class="time-values">{{ timeString }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import ProductTimeOfDay from './ProductTimeOfDay.vue';
import Base64ProductImage from './Base64ProductImage.vue';


export default {
    props: [
        'product',
        'display',
        'finalPrint'
    ],
    data() { return {
        times: this.product.times,
    }}, 
    components: {
        ProductTimeOfDay, Base64ProductImage
    },
    computed: {
        productName() {
            return (typeof this.product['otherName'] !== 'undefined') ? this.product.otherName : this.product.name;
        },
        singularFormat() {
            if (this.product.format.toLowerCase() == 'rx') {
                return '<span class="rx">Rx</span> / Treatment';
            }
            return this.product.format.replaceAll(/s(\/|$)/g,"$1");
        },
        isEditable() {
            return (!this.finalPrint && (this.product.format == 'Rx' || this.product.name == "Other"))
        },
        timeString() {
            return Object.keys(this.product.times).filter((e)=>{return this.product.times[e]}).join(', ');
            //return ['Morning','Midday','Evening'];
        }
    },
    methods: {
        toggleTime(target,value) {
            console.log(target,value);
            this.times[target] = value;
        },
    }
}
</script>
