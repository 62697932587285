
<template>
    <a :href="reportURL" target="_blank">
        <div v-if="!chartAPIFail" class="chart-api">
            <div class="wrapper" v-if="chartAPILoading"><div class="loader"></div></div>
            <img ref="apiQR">
        </div>
        <div v-class="{ 'hidden': !chartAPIFail }" class="qrcanvas">
            <img :src="qrImg">
            <qrcode-vue :value="reportURL" :size="300" level="M" render-as="canvas" margin="3"></qrcode-vue>
        </div>
    </a>
</template>

<script>
import router from '@/router';
import QrcodeVue from 'qrcode.vue';

export default {
    props: [
        'lookupid'
    ],
    data() {
        return {
            qrImg: '',
            chartAPILoading: true,
            chartAPIFail: false
        }
    },
    computed: {
        ID() {
            if (this.lookupid) {
                return "/" + this.lookupid;
            }
            return "";
        },
        reportURL() {
            let baseURL = window.location.origin + router.options.history.base;
            if (process.env.VUE_APP_LOCAL_IP) {
                baseURL = baseURL.replace('localhost',process.env.VUE_APP_LOCAL_IP);
            }
            let route = `/get${this.ID}`;
            return baseURL+route;
        },
        chartAPIURL() {
            // console.log("ENVIRONMENT: ",process.env);
            const GoogleQR = "https://chart.googleapis.com/chart?cht=qr&chs=300x300&chld=M|3&chl=";
            return GoogleQR+encodeURIComponent(this.reportURL);
        }
    },
    methods: {
        APIFail() {
            this.chartAPIFail = true;
            this.chartAPILoading = false;
            this.qrImg = document.querySelector('.qrcanvas canvas').toDataURL('image/png');
        },
        APISucceed() {
            this.chartAPILoading = false;
        }
    },
    components: {
        QrcodeVue
    },
    mounted() {
        /** 
         * Google Chart API claims that it's depracated, but there's no QR code chart in the newer version.
         * This seems to keep working fine and renders the cleanest QR code, but occassionally runs into loading issues
         * and will fail to load the image.
         * This code block queues up load and fail events and then triggers the fail method if the QR hasn't loaded
         * after a set period of time (native http timeout spins for too long)
         */
        setTimeout(()=>{
            if ( !this.$refs.apiQR.complete || !this.$refs.apiQR.naturalWidth ) {
                // Google Chart API QRCode failed to load after 2 seconds.
                this.APIFail();
            }
        },100);
    }
}
</script>

<style scoped lang="scss">
    .chart-api {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 98%;
            height: 98%;
            margin: 1%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border: 1px solid var(--color-border-grey);
            background-color: var(--color-about-background);
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    img {
        width: 100% !important;
        height: auto !important;
    }
    .qrcanvas {
        padding: 0;
        line-height: 0;
        &.hidden {
            display: none;
        }
        canvas {
            display: none;
        }
    }
</style>