<template>
    <div class="rx-cart" :class="{ 'mobile-only': mobile, 'mobile-hide': !mobile }">
        <h2>Select recommended products to build your patient's regimen</h2>
        <button class="rx white" @click="addRxProduct">Add <span class="rx">Rx</span> / Treatment</button>
    </div>

    <overlay-modal v-if="showModal" @close="showModal = false" ref="modal">
        <product-other-form :product="activeModalProduct" @update="toggleCartItem" @close="showModal = false"></product-other-form>
    </overlay-modal>
</template>

<script>
import { useCatalogStore, useCartStore } from '@/stores';
import OverlayModal from './OverlayModal.vue';
import ProductOtherForm from './ProductOtherForm.vue';
export default {
    setup() {
        const catalog = useCatalogStore();
        const cart = useCartStore();
        return { catalog, cart };
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false
        }
    }, 
    data() { return {
        activeModalProduct: null,
        showModal: false,
        mobile: this.isMobile
    }},
    methods: {
        addRxProduct() {
            this.activeModalProduct = this.catalog.rxProduct;
            this.showModal = true;
            console.log(this.activeModalProduct);
            return null;
        },
        toggleCartItem(product) {
            this.cart.toggleProduct(product);
            this.showModal = false;
        }
    },
    components: {
        OverlayModal, ProductOtherForm
    }
}
</script>