<template>
    <section class="single-col review">

        <a href="#" @click="addMore" class="add-more"><i class="icon arrow left blue" style="margin-right: .3em;"></i>Add more products</a>

        <div class="top-row">
            <h1>Confirm your patient's regimen</h1>
            <button v-if="!empty" @click.prevent="validateAndShare" class="mobile-hide">Share Patient Regimen</button>
        </div>

        <div v-if="empty">
            <p>There are no products currently in your regimen recommendation.</p>
            <router-link to="/products">Return to the catalog</router-link>
        </div>

        <div v-if="cart.cartItems.length > 0">
            <div v-for="condition in cart.activeConditions" v-bind:key="condition.id">
                <h3 class="condition-label">{{ condition.name }}</h3>
                <div name="list" class="review-list-wrapper" tag="div">
                    <ProductCartTile class="" v-for="item in productCondition(condition.name)" v-bind:key="item.id" :product="item"
                    @remove-row="removeCartItem" @editProduct="editOtherProduct">{{ item.name }}</ProductCartTile>
                </div>
            </div>
        </div>
        <div class="text-panel" v-if="cart.cartItems.length > 0">
            <label>Add Additional Notes</label>
            <div style="position: relative;">
                <TextareaPIIWarning :visible="activeField == 'additional-notes'"></TextareaPIIWarning>
                <textarea class="editor additional-notes" v-model="additionalNotes" placeholder="Add additional notes here (optional)"
                @focus="activeField = 'additional-notes'" @blur="activeField = ''" ></textarea>
            </div> 

            <label>Skin Condition Notes</label>
            <div>
                <transition name="list">
                    <div v-if="cart.activeConditionNotes.length == 0" div class="condition-content"><strong>No skin condition notes will be included in the patient regimen.</strong></div>
                </transition>
                <div v-for="condition in cart.productConditionNotes" v-bind:key="condition.id" class="condition-content" :class="{ short: !condition.active }">
                    <transition name="list">
                        <div v-if="condition.active">
                            <h3>About {{ condition.name }}</h3>
                            <div class="actions">
                                <a href="#" @click.prevent="editConditionText(condition)" class="edit-link">Edit notes</a>
                                <a href="#" @click.prevent="cart.toggleCondition(condition.name)" class="remove-link">Delete notes</a>
                            </div>
                            <div v-html="condition.description" class="tip-text"></div>
                            <h4>{{ tipName(condition) }} Skincare Tips</h4>
                            <div v-html="condition.tips" class="tip-text"></div>
                        </div>
                        <div v-else>
                            <h3 class="tip-text normal">Skin condition notes will not be included for <strong>{{ condition.name }}.</strong></h3>
                            <div class="actions">
                                <a href="#" @click.prevent="cart.toggleCondition(condition.name)" class="reset-link">Restore notes</a>
                            </div>       
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <div class="share-button">
            <button v-if="!empty" @click.prevent="validateAndShare" class="mobile-hide">Share Patient Regimen</button>
        </div>
        <button  v-if="!empty" @click.prevent="validateAndShare" class="mobile-only fixed">Share Patient Regimen&nbsp;<i class="icon arrow right white"></i></button>         


    </section>
    <overlay-modal v-if="editProduct" @close="editProduct = false" ref="modal">
        <product-other-form :product="activeModalProduct" @update="toggleCartItem" @close="editProduct = false"></product-other-form>
    </overlay-modal>
    <overlay-modal v-if="showModal" @close="closeOverlay">
        <conditionEditor :condition="conditionEdit" @update="updateConditionText" />
    </overlay-modal>
</template>

<script>
import { useCatalogStore, useCartStore } from '@/stores';
import ProductCartTile from '@/components/ProductCartTile.vue';
import OverlayModal from '@/components/OverlayModal.vue';
import { RouterLink } from 'vue-router';
import ConditionEditor from '@/components/ConditionEditor.vue';
import router from '@/router';
import ProductOtherForm from '@/components/ProductOtherForm.vue';
import TextareaPIIWarning from '@/components/TextareaPIIWarning.vue';

export default {
    setup() {
        const catalog = useCatalogStore();
//        catalog.getCatalog();
        const cart = useCartStore();
        return { catalog, cart };
    },
    mounted() {
//        console.log("Review cart composition",this.cart);
        window.scrollTo({
            top: 0
        });
    },
    data() { return {
        activeField: '',
        editProduct: false,
        showModal: false,
        additionalNotes: "",
        placeholder: "Add additional notes here...",
        activeModalProduct: null,
        conditionEdit: []
    }},
    computed: {
        formats() { 
            return [...['Rx'],...this.catalog.formats].filter((format)=>{ return this.cart.formatCount(format); });
        },
        empty() {
            return this.cart.cartItems.length == 0;
        }
    },
    components: {
        ProductCartTile,OverlayModal,RouterLink,ConditionEditor,ProductOtherForm, TextareaPIIWarning
    },
    methods: {
        addMore() {
            document.querySelector('main').classList.remove('show-cart');
            router.push('/products');
        },
        productFormat(filterFormat) {
            return this.cart.cartItems.filter((x) => { return x.format == filterFormat; });
        },
        tipName(condition) {
            return condition.name.replace(/( skin|skin )/i,"");
        },
        productCondition(filterCondition) {
            return this.cart.cartItems.filter((x) => { return x.activeConditions.includes(filterCondition); });
        },
        closeOverlay() {
            this.showModal = false;
        },
        editOtherProduct(otherProduct) {
            this.activeModalProduct = otherProduct;
            this.editProduct = true;
            return null;
        },
        removeCartItem(product) {
            this.cart.removeProduct(product);
        },
        editConditionText(condition) {
            this.conditionEdit = condition;
            this.showModal = true;
            return condition;
        },
        updateConditionText(condition) {
            this.cart.updateConditionText(condition);
            this.showModal = false;
        },
        validateAndShare() {
            /** Validate and submit (async) to AppSheet */
            this.cart.additionalNotes = this.additionalNotes;
            router.push('/share');
        }
    }
}
</script>
<style lang="scss" scoped>


textarea.additional-notes {
    width: 100%;
    min-height: 6em;
}





.text-panel {
    background: var(--color-about-background);
    padding: 20px 40px 20px 40px;
    margin-top: 40px;
    color: var(--color-tip-text);

    display: grid;
    grid-template-columns: 7em 1fr;
    grid-gap: 2em 1em;

    label {
        color: black;
        font-size: .95em;
    }

    label {
        text-transform: uppercase;
        font-weight: bold;
    }

    .condition-content {
        position: relative;
        margin-top: 2em;
        line-height: 1.35;
        padding: .5em 0 1em 0;
        border-bottom: 1px solid var(--color-border-grey);

        &.short {
            margin: 1em 0 0 0;
            padding: 0 0 1em 0;
        }

        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            border-bottom: none;
        }

        .actions {
            position: absolute;
            top: 0;
            right: 0;
            font-size: .9375em;

        }
        h3 {
            text-transform: none !important;
            color: var(--color-dark-blue);
            margin-top: 0;
        }
        h4 {
            margin-bottom: 0em;
        }
        .tip-text {
            color: var(--color-tip-text);
        }
        ul {
            margin-top: .5em;
            li {
                margin-bottom: .75em;
            }
        }
    }



    @media screen and (max-width: 760px) {
        grid-template-columns: 1fr;
        grid-gap: 1em;
        padding: 10px 20px;

        label {
            //color: var(--color-dark-blue) !important;
        }
        /*
        label {
            margin-top: 1em;
            background-color: var(--color-dark-blue);
            padding: .5em;
            color: var(--color-about-background) !important;
        }
        */

        .condition-content {
            padding-top: 0 !important;
            .actions {
                top: -1em;
                right: auto;
                left: 0;
                position: relative;

                a {
                    margin-left: 0 !important;
                    padding-right: 1em;
                }
            }
        }
    }
}
.share-button {
    margin-top: 2em;
    text-align: center;
}

.editor {
    background: white;
    min-height: 150px;
    max-height: 250px;
    overflow: scroll;
}
</style>

