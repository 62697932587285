import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../pages/HomeAuthPage.vue';
import Catalogue from '../pages/ProductCataloguePage.vue';
import Review from '../pages/RecommendationReviewPage.vue';
import Submit from '../pages/SubmitRecommendationPage.vue';
import Get from '../pages/GetRegimenPage.vue';
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue';

import { useAuthStore, useCartStore } from '@/stores/';
import ZzProductList from '@/pages/zzProductList.vue';

const routes = [
    {    
        path: '/',
        component: Home,
        meta: {
            noAuth: true,
            hideHeader: true,
            hideSidebar: true,
            title: "TheReco",
            cssClass: "home"
        }
    },
    {
        path: '/products',
        title: "TheReco: Create a skincare regimen for your patients",
        component: Catalogue
    },
    {
        path: '/reset',
        component: Catalogue
    },
    {
        path: '/review',
        title: "TheReco: Review your patient regimen",
        component: Review
    },
    {
        path: '/share',
        component: Submit,
        meta: {
            regimenComplete: true,
            hideSidebar: true,
            title: "TheReco: Share your patient's regimen",
            cssClass: "final"
        }
    },
    {
        path: '/get/:id?',
        component: Get,
        props: true,
        meta: {
            noAuth: true,
            hideHeader: false,
            hideFooter: false,
            hideSidebar: true,
            getRegimen: true,
            title: "TheReco: My skincare regimen",
            cssClass: "final"
        }
    },
    {
        path: '/privacy',
        component: PrivacyPolicy,
        meta: {
            noAuth: true,
            hideSidebar: true,
            title: "TheReco: Privacy Policy"
        }
    },
    {
        path: '/zzproductlist',
        component: ZzProductList,
        meta: {
            noAuth: false,
            hideSidebar: true,
            title: "TheReco: Product Master List"
        }
    },
    {
        path: '/:pathMatch(.*)*',   
        redirect: '/'
    }
];

export const router = createRouter({
    scrollBehavior: function() {
        return { x: 0, y: 0 };
    },
    history: createWebHashHistory(),
    routes
});

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page 
    // console.log("ROUTER",to);
    const authRequired = !to.meta.noAuth;
    console.log((authRequired) ? "Authorization required" : "Public page - no auth required");
    const authStore = useAuthStore();

    if (to.path == '/' && authStore.isAuthenticated) {
        return '/products';
    }

    if (to.path == '/reset' && authStore.isAuthenticated) {
        const cart = useCartStore();
        cart.resetCart();
        return '/products';
    }

    if (authRequired && !authStore.isAuthenticated) {
        // authStore.returnUrl = to.fullPath;
        return '/';
    }
});

router.afterEach((to, from) => {
        document.body.classList.remove(from.meta.cssClass);
        document.body.classList.add(to.meta.cssClass);
        document.title = to.meta.title || "TheReco";
})

export default router;