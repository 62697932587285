<template>
  <section>
    <div class="authPanel">
      <h1><img src="@/assets/theReco.svg" class="siteLogo" alt="TheReco" ></h1>
      <NPIValidator />
    </div>
  </section>
</template>

<script>
import components from '@/components'
export default {
  components
}
</script>

<style lang="scss" scoped>

section {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.authPanel {
  background: var(--color-content-background);
  box-sizing: border-box;
  width: 90dvw;
  max-width: 688px;
  padding: 70px 90px;
  border-radius: 10px;

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .siteLogo {
    width: 100%;
    max-width: 310px;
    height: auto;
  }

  @media screen and (max-width: 700px) {
    box-sizing: border-box;
    padding: 10%;
  }
}

</style>