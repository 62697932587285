import { defineStore } from 'pinia';
import axios from 'axios';

import { router } from '@/router';
import { useAlertStore } from '@/stores/alert.store';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(window.sessionStorage.getItem('npiref')),
        validNPI: false,
        returnUrl: null,
        ignoreFirstName: true,
        testNPI: '2155394255'
    }),
    getters: {
        isAuthenticated: (state) => {
            console.log("Checking authentication for router ",router,state.user);
            if (state.user) {
                console.log("User exists in session storage");
                return true;
            }
            console.log("User does not have an active session.");
            let user = window.localStorage.getItem('npiref');
            console.log("Checking for 'remember me' status",user);
            if (user) {
                user = JSON.parse(user);
                if ((new Date(user.expiration)).getTime() > Date.now()) {
                    state.user = user;
                    window.sessionStorage.setItem('npiref',JSON.stringify(user));
                } else {

                    window.localStorage.removeItem('npiref');
                }
            }
            return (state.user);
        }
    },
    actions: {
        async validateNPI(firstname, npinumber) {
            try {
                var user  = {
                    npi: npinumber,
                    fname: firstname,
                    ts: new Date((new Date()).setDate((new Date()).getDate() + 30))
                }
                // update pinia state
                this.user = user;
                this.validNPI = true;

                // store user details and jwt in local storage to keep user logged in between page refreshes
                //window.localStorage.setItem('npiref', JSON.stringify(user));

                // redirect to previous url or default to home page
                router.push(this.returnUrl || '/');
            } catch (error) {
                const alertStore = useAlertStore();
                alertStore.error(error);
            }
        },
        async validateNPI_NIH(firstname, npi, rememberme, returnURL) {
            let reply = {
                status: 'success',
                message: '',
                fields: []
            };
            // https://clinicaltables.nlm.nih.gov/apidoc/npi_idv/v3/doc.html
        
            // Check if the NPI is a valid format
            var regex = /^[0-9]{10}$/;
            if (!regex.test(npi)) {
                reply.status = 'error';
                reply.fields.push('npinumber');
            }
            if (!this.ignoreFirstName && firstname.length == 0) {
                reply.status = 'error';
                reply.fields.push('firstname');
            }
        
            if (reply.status != 'error') {
                // if (npi == this.testNPI) {
                //     console.log("Match on temporary test NPI");
                //     this.user = {
                //         firstname,
                //         npi
                //     }
                //     window.sessionStorage.setItem('npiref',JSON.stringify(this.user));
                //     this.validNPI = true;
                // }

                try {
                    let response = await axios.get('https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search?df=NPI,name.full,name_other.full&sf=NPI,name.first&terms='+npi);
                    // do something with the response
                    if (!response.data[0]) {
                        this.errormsg = "No NPI number found matching "+this.npinumber+". Please try again";
                        reply = {
                            status: 'fail',
                            fields: 'npinumber',
                            message: 'NPI number is not valid'
                        }
                    }

                    var results = response.data[3];
                    for(var i = 0; i < results.length && !this.npi; i++) {
                        var matches = [];
                        if (this.ignoreFirstName || results[i][1].toUpperCase().includes(firstname.toUpperCase())) {
                            matches.push('first');
                        }
            
                        console.log("Matches array",matches);
                        if (matches.length > 0) {
                            this.message = "SUCCESS: Match on " + matches.join(" and ") + " name.";
                            this.validNPI = true;

                            this.user = {
                                firstname,
                                npi
                            }
                            window.sessionStorage.setItem('npiref',JSON.stringify(this.user))
                            if (rememberme) {
                                this.user.expiration = new Date((new Date()).setDate((new Date()).getDate() + 30))
                                window.localStorage.setItem('npiref',JSON.stringify(this.user))
                            }
                        } else {
                            this.validNPI = false;
                            console.log("invalid login");
                            reply = {
                                status: 'fail',
                                fields: 'npinumber',
                                message: 'NPI number not found'
                            }
                        }
                    }
                } catch(error) {
                    // handle the error
                    console.log(error);
                    reply = {
                        status: 'fail',
                        message: 'Network error. Please try again later.'
                    };
                }
            }
            console.log("Out of the try/catch");
        
            if (this.validNPI) {
                router.push(returnURL);
            }
            return reply;
        },
        logout() {
            console.log("Logging out");
            this.user = null;
            this.validNPI = null;
            localStorage.removeItem('npiref');
            sessionStorage.removeItem('npiref');
            router.push('/');
        }
    }
});