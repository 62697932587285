import { appSheet } from '@/api/appSheet';
import { defineStore } from 'pinia';
//import typeahead from './typeahead.json';

const PREDICTIVE_EXPIRATION_DAYS = 1;

export const useTypeaheadStore = defineStore({
    id: 'typeahead',
    state: () => ({
        typeahead: {}
    }),
    getters: {
        getOthers: (state) => {
            return (format) => { 
                format = format.toLowerCase();
                console.log("Inside typeahead",format,state.typeahead);
                if (format in state.typeahead) {
                    return [...state.typeahead[format]] ;
                }
                return [];
            }
        }
    },
    actions: {
        async loadValues() {
            let typeahead = window.localStorage.getItem('tas');
            if (typeahead && typeahead.length > 0) {
                typeahead = JSON.parse(typeahead);
                if (typeahead && new Date(typeahead.expiration).getTime() > Date.now()) {
                    this.typeahead = typeahead.data;
                } else {
                    this.typeahead = {};
                }
            }

            if (JSON.stringify(this.typeahead) === "{}") {
                typeahead = {};
                console.log("Refreshing predictive text listings");
                let results = await appSheet.request('PredictiveText',{})
                    .then((response)=>{
                        return response.data;
                    });
                results.forEach(item => {
                    let format = item.FormatLabel.toLowerCase();
                    if (!typeahead[format]) {
                        typeahead[format] = [];
                    }
                    typeahead[format].push(item.product); 
                });
                this.typeahead = {...typeahead};

                window.localStorage.setItem('tas',JSON.stringify({
                    'data': this.typeahead,
                    'expiration': new Date((new Date()).setDate((new Date()).getDate() + PREDICTIVE_EXPIRATION_DAYS))
                }));
            }
        }
    }
});