import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { router } from './router';
import TheReco from './TheReco.vue';
import SimpleTypeahead from 'vue3-simple-typeahead';
import piniaPluginPersistedState from "pinia-plugin-persistedstate";

import 'normalize.css';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@/assets/css/main.scss';

import { QuillEditor } from '@vueup/vue-quill'

console.log("TheReco\n------\nApp Version: " + process.env.VUE_APP_GIT_HASH, {
  rev: process.env.VUE_APP_GIT,
  long: process.env.VUE_APP_GIT_BRANCH
});

const app = createApp(TheReco);
app.use(createPinia().use(piniaPluginPersistedState));
app.use(SimpleTypeahead);
app.use(router);

/** Post security audit -- disable GA4 analytics and measurement */
/*
import VueGTag from "vue-gtag";
app.use(VueGTag, {
  config: { id: "G-6N0V8V8BY5" }
}, router);
*/

const globalOptions = {
    modules: {
      toolbar: [
        ['bold','italic','underline'],
        [{list:'bullet'},{list:'ordered'}],
        ['clean']
      ]
    },
    theme: 'snow'
}
// set default globalOptions prop
QuillEditor.props.globalOptions.default = () => globalOptions
app.component('QuillEditor', QuillEditor)
app.component('SimpleTypeahead', SimpleTypeahead);

let production = process.env.NODE_ENV;
if ( production == 'production') {
    // Sorry: it's crude, but turns off the logging in production
    console.backupLog = console.log;
    console.log = ()=>{};
    // L'Oreal Enterprise solution (via navneet.verma)
    /** v1.0
     * Initial build. */
    // app.provide('appSheetAppId','605b168f-2894-44ba-837b-cf8283606c69');
    // app.provide('appSheetKey','V2-EOUcW-8gaL6-NQ4Ph-NyeAl-2QrO4-hI5v0-gC6rb-vwDyi');
    /** v2.0 - Analytics */
/**
 * Moving AppID and Key to appsheet.api 
 *
    app.provide('appSheetAppId','c1ac2b4e-e9e8-465a-b186-070515332099');
    app.provide('appSheetKey','V2-VnVlL-cL60A-oy0NO-LPXN6-HQuAh-gLhWU-0mD1y-8wjc7');
} else {
    // Dev's Lucid Group prototype
    app.provide('appSheetAppId','ab86d281-fba1-4961-99fb-0c3f38bc873b');
    app.provide('appSheetKey','V2-ZZWzo-0xR9N-FpFoe-yyLCw-loNGn-r6tdn-uiSvL-uaKD1');
*/
}

app.mount('#TheReco');

