<template>
    <section class="single-col static">

        <h1>Privacy Policy</h1>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non venenatis ligula. Suspendisse potenti. Aenean a viverra nulla. Aenean vitae mattis odio, vitae sollicitudin leo. Quisque hendrerit volutpat lobortis. Vivamus porttitor leo a nibh molestie mollis. Duis magna neque, feugiat ut aliquet nec, hendrerit vitae felis. Ut semper ornare iaculis. Maecenas aliquet mauris sem, dignissim commodo sem gravida in. Nam felis eros, auctor eget justo rhoncus, gravida posuere erat. Nunc sed sodales libero.
        </p>
        <p>
        Quisque tempus ipsum sit amet lorem finibus suscipit. Integer pulvinar hendrerit facilisis. Maecenas vel justo id nisl accumsan mattis eu hendrerit nunc. Nulla sodales viverra semper. Duis eu sapien vel libero condimentum ullamcorper. Nullam ultricies sem nec libero dignissim, non aliquet risus maximus. Vestibulum in tortor eget libero interdum rutrum. Praesent sollicitudin justo nec lacinia accumsan. Duis volutpat, neque in vulputate cursus, ipsum magna sagittis dolor, vel congue neque massa vitae nibh. Phasellus tincidunt nibh non ex rhoncus, at euismod augue maximus. Maecenas imperdiet consequat tellus, sit amet ornare nulla tempor vitae. Mauris finibus vitae metus vel tempor. Pellentesque pharetra sollicitudin sapien eget sodales.
        </p>
        <p>
        In libero ante, porta sit amet porta vel, iaculis sed eros. Fusce elementum justo et sodales ultricies. Sed mi odio, euismod ac interdum id, aliquet eu metus. Integer egestas molestie augue eget aliquam. Sed molestie dui id rutrum commodo. Praesent ornare ut dolor id blandit. Nulla posuere dignissim porta. Nullam dolor diam, tincidunt sed ipsum sed, tincidunt fermentum ex. Aliquam eget dignissim justo. Mauris bibendum enim risus, sed ornare mi finibus sit amet. Cras nec cursus libero, id sollicitudin justo.
        </p>
        <p>
        Phasellus eu bibendum leo, sit amet sodales felis. Vivamus nec nunc sit amet augue venenatis dapibus. In et volutpat sapien, hendrerit eleifend ex. Curabitur sodales eu ipsum id iaculis. Nullam ut neque dictum tellus efficitur congue eget id mi. Pellentesque sed lacinia velit. Nullam sagittis scelerisque arcu, eget semper diam consequat eleifend. Integer lacinia turpis ac risus ornare, nec rutrum turpis ornare. Donec tempor, leo vel porttitor cursus, arcu tellus lacinia ex, vel molestie purus justo eget metus. Fusce ornare pretium nunc, quis convallis magna dapibus eu. Donec vestibulum ac diam quis sagittis. Cras fermentum vitae lectus tincidunt cursus. Vivamus quis elementum arcu.
        </p>
        <p>
        Duis semper malesuada orci. Integer iaculis eros sed nunc faucibus, et eleifend orci ornare. Proin ac turpis sed eros laoreet aliquet. Phasellus consequat nulla vel nisl gravida tempus. Etiam viverra turpis orci, nec laoreet sem pulvinar vitae. Pellentesque pulvinar leo et orci ultricies varius. Curabitur eu neque id lacus viverra efficitur quis ut justo. Mauris leo orci, facilisis at tellus sit amet, luctus dapibus nibh. Aliquam posuere, elit at fermentum accumsan, erat augue tristique magna, et lobortis erat dui sit amet nisi. Proin vitae felis sit amet nisl auctor pretium. Nunc molestie dapibus quam vel fermentum. Vestibulum nec finibus velit, vitae semper neque.
        </p>
    </section>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>