<template>
    <table class="mplist" border="1" cellspacing="0" cellpadding="0">
        <tr class="row header">
            <td class="order">Order</td>
            <td class="image">Image</td>
            <td class="name">Name</td>
            <td class="format">Format</td>
            <td class="condition">Conditions</td>
        </tr>
        <tr class="row" v-for="product in plist" v-bind:key="product.id">
            <td class="order">{{ product.order }}</td>
            <td class="image"><Base64ProductImage :src="product.image" :file="product.fileName"></Base64ProductImage></td>
            <td class="name">{{ product.name }}</td>
            <td class="format">{{ product.format }}</td>
            <td class="condition"><ul>
                <li v-for="condition in product.conditions" v-bind:key="condition">{{ condition }}</li>
            </ul></td>
        </tr>
    </table>
</template>

<script>
import Base64ProductImage from '@/components/Base64ProductImage.vue';
export default {
    setup() {
        
    },
    components: {
        Base64ProductImage
    },
    computed: {
        plist() {
            return JSON.parse(window.localStorage.getItem('catalog')).products;
        }
    }
}
</script>

<style lang="scss" scoped>
    .mplist {
        background: white;
        padding: 2em;
        border: 1px solid #ccc;
        .row {
            display: table-row;
            grid-template-columns: 30px 150px 1fr 1fr 1fr;

            border: 1px solid #ccc;
            border-width: 0px 0px 1px 1px;
            padding: 0;
            margin: 0;

            &.header {
                font-weight: 900;
            }
            &>div {
                display: table-cell;
                position: relative;
                border: 0px solid #ccc;
                border-width: 1px 1px 0 0;
            }
            img {
                width: 100%;
                max-width: 100px;
                height: auto;
            }
            ul {
                margin: 0 0 0 1em;
                padding: 0;
            }
        }
    }
</style>