<template>
    <div class="overlay-outer loading" @close="closeOverlay()">
        <div class="overlay-background"></div>
        <div class="overlay-inner">
            <div class="overlay-content">
                <div class="loader"></div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    mounted() {
        // Modal is mounted
    },
    props: {
        product: null
    },
    methods: {
        closeOverlay() {
            this.$emit('close');
        }
    }
})
</script>

<style lang="scss">
.overlay-outer.loading {
    .overlay-content {
        text-align: center;
        .loading {
            margin-bottom: 1em;
        }
    }
}
</style>