<template>
  <site-header v-if="!$route.meta.hideHeader"></site-header>
  <main :class="{ noHeader: $route.meta.hideHeader }">
    <router-view></router-view>
  </main>
  <site-footer v-if="!$route.meta.hideFooter"></site-footer>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import { useCatalogStore, useCartStore, useTypeaheadStore } from '@/stores';

export default {
  name: 'App',
  setup() {
    const catalog = useCatalogStore();
    catalog.getCatalog();
    const cart = useCartStore();
    const typeahead = useTypeaheadStore();
    typeahead.loadValues();
    return { catalog, cart };
  },
  components: {
    SiteFooter,
    SiteHeader
  },
  methods: {

  },
  data() {
    return {
    };
  }
}
</script>

<style lang="scss">
  header {
    z-index: 2;
  }

  main {
    flex: 1 1 auto;
    position: relative;
    display: flex !important;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }

</style>

