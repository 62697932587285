<template>
    <div class="action-panel" :if="lookupid">
        <button id="download" @click="exportToPDF">DOWNLOAD PDF</button>
        <q-r-code id="qrcode" :lookupid="lookupid"></q-r-code>
        <button class="mobile-only fixed mobile-download-button" :if="lookupid" @click="exportToPDF">DOWNLOAD PDF</button>
    </div>
    <OverlayLoading v-if="loading">
        <p>Generating your PDF...</p>
    </OverlayLoading>
</template>

<script>
import QRCode from './QRCode.vue';
import html3pdf from "html3pdf";
import OverlayLoading from './OverlayLoading.vue';


export default {
    props: [
        "lookupid"
    ],
    setup() {
        
    },
    components: {
        QRCode, OverlayLoading
    },
    data() {
        return {
            loading: false
        };
    },
    mounted() {
        window.addEventListener('scroll', ()=>{
            this.buttonPosition();
        });
        setTimeout(this.buttonPosition, 600);
    },
    methods: {
        buttonPosition() {
            let ap = document.querySelector('.action-panel');
            if (ap) {
                let headerHeight = parseInt(getComputedStyle(ap).getPropertyValue('--header-height'));
                let box = ap.getBoundingClientRect();
                let offset = headerHeight + 15 + box.top - headerHeight;
                offset = (offset > 0) ? offset : 0;
                ap.style.setProperty('--mobile-offset',offset+"px");
            }
        },
        togglePrint() {
            console.log()
            document.getElementsByTagName('body').classList.toggle("print");
        },
        async exportToPDF() {
            this.loading=true;
            let main = document.querySelector("#TheReco").cloneNode(true);
            main.classList.add('print');
            if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 ) {
                main.classList.add('safari');
            }
            main.style.width = '7.5in';
            main.querySelector('.print-footer').remove();

//            console.log(main);

            let options = {
                    margin: [0.25,0.5,1.35,0],
                    image: {
                        type: 'jpg',
                        quality: .5
                    },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                    filename: `yourSkincareRegimen-${this.lookupid}.pdf`,
                    pagebreak: {
                        mode: 'css',
                        avoid: ['.condition-content','ul','p']
                    },
                    html2canvas: {
                        dpi: 192,
                        windowWidth: 1440,
                        scale: 1.5,
                        allowTaint: true,
                        useCORS: true,
                        letterRendering: true
                    }
                };
            await html3pdf().set(options).from(main).toPdf().get('pdf').then((pdf)=>{
                var img = {
                    'logo': {
                        src: document.querySelector(".print-footer .siteLogo"),
                        width: 0,
                        height: .5
                    },
                    'powered': {
                        src: document.querySelector(".print-footer .poweredby"),
                        width: 0,
                        height: 0
                    }
                };
                img.logo.width = img.logo.height * img.logo.src.width / img.logo.src.height;
                img.powered.width = img.logo.width * 1.5;
                img.powered.height = img.powered.width * img.powered.height / img.powered.width;
                let margin = {
                    width: .65,
                    height: .75
                };
                //console.log("Target dimensions: " + targetWidth + "x"+targetHeight+" inches");
                    var totalPages = pdf.internal.getNumberOfPages(); 
                    //print current pdf width & height to console
                    console.log("getHeight:" + pdf.internal.pageSize.getHeight());
                    console.log("getWidth:" + pdf.internal.pageSize.getWidth());
                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        //divided by 2 to go center
                        pdf.text('Page ' + i + ' of ' + totalPages, margin.width + .1,
                                pdf.internal.pageSize.getHeight() - .3);

                        pdf.setFontSize(6)
                        pdf.setTextColor(204);
                        let debugString = `${process.env.VUE_APP_GIT}    ${process.env.VUE_APP_GIT_BRANCH}    (${window.innerWidth}x${window.innerHeight})    `
                                    + window.location.href
                                    + ((this.$route.meta.getRegimen) ? "" : `    (${this.lookupid})`) + "\n"
                                    + navigator.userAgent;
                        // Clear the debug string for production
                        debugString = '';
                        pdf.text(debugString,
                                pdf.internal.pageSize.getWidth() - margin.width,
                                pdf.internal.pageSize.getHeight() - .3, 'right');

                        pdf.setFontSize(10);
                        pdf.setTextColor("#023967");
/*                        pdf.text('Powered by',
                            pdf.internal.pageSize.getWidth()-margin.width-targetWidth-.8,
                            pdf.internal.pageSize.getHeight()-margin.height-targetHeight*.4);
*/
                        pdf.addImage(img.logo.src,'PNG',
                            pdf.internal.pageSize.getWidth()-margin.width-img.logo.width,
                            pdf.internal.pageSize.getHeight()-margin.height-img.logo.height, 
                            img.logo.width, img.logo.height);
                        pdf.addImage(img.powered.src,'PNG',
                            pdf.internal.pageSize.getWidth()-margin.width-img.powered.width,
                            pdf.internal.pageSize.getHeight()-margin.height, 
                            img.powered.width, img.powered.height);
                        pdf.setDrawColor("#023967");
                        pdf.setLineWidth(0.05);
                        pdf.line(margin.width,10.53,pdf.internal.pageSize.getWidth()-margin.width,10.53);
                        
                    } 
                }).save();
            console.log("Resetting style");
            main.classList.remove('print');
            this.loading=false;
        }
    }
}
</script>

<style lang="scss" scoped>
.action-panel {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    --mobile-offset: var(--header-height);
    
    #qrcode {
        width: 175px;
        height: 155px;
        max-width: 25%;
        height: auto;
    }

    button.fixed.mobile-download-button {
        transform: translateY(var(--mobile-offset));
    }
}
</style>
