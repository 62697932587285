<template>
    <section class="single-col">
        <table>
            <tbody>

                <div class="top-row">
                    <h1 class="screen-only">Share your patient's regimen</h1>
                    <h1 class="print-only">My skincare regimen</h1>
                    <regimen-action-panel :lookupid="lookupID" v-if="!empty"></regimen-action-panel>
                </div>

                <div v-if="empty">
                    <p>There are no products currently in your regimen recommendation.</p>
                    <router-link to="/products">Return to the catalog</router-link>
                </div>

                <final-reco-format :regimen="cart" v-if="!empty"></final-reco-format>

            </tbody>
            <tfoot>
                <regimen-print-footer></regimen-print-footer>
            </tfoot>
        </table>
    </section>
</template>

<script>
import { useCatalogStore, useCartStore } from '@/stores';
import FinalRecoFormat from '@/components/FinalRecoFormat.vue';
import router from '@/router';
import { appSheet } from '@/api/appSheet';
import RegimenActionPanel from '@/components/RegimenActionPanel.vue';
import RegimenPrintFooter from '@/components/RegimenPrintFooter.vue';

export default {
    setup() {
        const catalog = useCatalogStore();
        const cart = useCartStore();
        return { catalog, cart };
    },
    async mounted() {
        if (this.empty) return;

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        let activeID = this.cart.getLookupID;
        if (this.lookupID == activeID || this.cart.submitted) {
            this.lookupID = activeID;
            console.log(`This regimen has already been shared... not resubmitting for ID "${this.lookupID}" submitted=${this.cart.submitted}`,this.cart);
            return;
        }
        this.lookupID = activeID;
        if (!this.lookupID)
            router.push('/products');
        
        let jsonData = appSheet.chunkLargeString(this.cart.jsonCart());
        //console.log("Chunked string",jsonData);
        let rows = jsonData.map((jsonPart, index)=>{
            return {
                "LookupID": this.lookupID,
                "Part": index,
                "hasAdditionalNotes": this.cart.additionalNotes.length > 0,
                "JSON": jsonPart
            }
        })

        let request = {
            "Action": "Add",
            "Rows": rows
        };

        console.log("SUBMITTING RECORD",request);
        appSheet.request("Recommendation",request)
            .then((response)=>{
                console.log("AppSheet Response: ", response);
                this.cart.submitted = true;
            })
            .catch((error)=>{
                console.log("AppSheet Error: ",error);
            });

        /** Store anonymous cart product data for analytics */
        rows = this.cart.cartItems.map((item)=>{
            let condition = this.cart.conditions.find(cond => cond.name == item.activeConditions[0]);
            return {
                'LookupID': this.lookupID,
                'Hostname': window.location.host,
                "hasAdditionalNotes": this.cart.additionalNotes.length > 0,
                'Product':  item.name,
                'OtherName': item.otherName || "",
                'Instructions': item.instructions || "",
                'Morning': item.times.morning,
                'Midday': item.times.midday,
                'Evening': item.times.evening,
                'Condition': condition.name,
                'ConditionEdited': (condition.edited) || false,
                'ConditionHidden': !condition.active
            }
        });
        request = {
            "Action": "Add",
            "Rows": rows
        };
        appSheet.request("LogProduct",request)
            .then((response)=>{
                console.log("AppSheet Response: ", response);
                this.cart.submitted = true;
            })
            .catch((error)=>{
                console.log("AppSheet Error: ",error);
            });

        rows = this.cart.conditions.filter(condition=>condition.edited).map(row=>{ return {
            'lookupID': 'foo',
            'Category': row.name,
            'Description': row.description,
            'Tips': row.tips
        }});
        if (this.cart.additionalNotes) {
            rows.unshift({
                'LookupID': this.lookupID,
                'Category': 'Additional Notes',
                'Description': this.cart.additionalNotes
            });
        }
        request = {
            "Action": "Add",
            "Rows": rows
        };
        appSheet.request("LogContent",request)
            .then((response)=>{
                console.log("AppSheet Response: ", response);
                this.cart.submitted = true;
            })
            .catch((error)=>{
                console.log("AppSheet Error: ",error);
            });
    },
    data() { return {
        showModal: false,
        additionalNotes: "Additional notes here",
        formSubmitted: false,
        lookupID: ''
    }},
    components: {
        FinalRecoFormat, RegimenActionPanel, RegimenPrintFooter
    },
    computed: {
        qrURL() {
            const GoogleQR = "https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=";
            const baseURL = window.location.origin + router.options.history.base;
            return GoogleQR+encodeURIComponent(baseURL+'/get');
        },
        empty() {
            return this.cart.cartItems.length == 0;
        }
    },
    methods: {
        productFormat(filterFormat) {
            return this.cart.cartItems.filter((x) => { return x.format == filterFormat; });
        },
        JSONDUMP() {
            console.log(this.cart,this.cart.slimCart(),this.cart.jsonCart());
        }
    }
}


</script>







