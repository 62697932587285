<template>
    <footer>
        <div class="container">
            <router-link to="/"><img class="siteLogo" src="@/assets/theReco-KO.svg" alt="theReco" /></router-link>
            <nav>
                <ul>
                    <li v-if="1 == 2"><router-link to="/privacy">Privacy Policy</router-link></li>
                    <li><a href="https://www.lorealdermatologicalbeauty.us/privacy-policy" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.lorealdermatologicalbeauty.us/terms-and-conditions" target="_blank">Terms of Use</a></li>
                </ul>
            </nav>
        </div>
        <div class="poweredby container">
            <img src="@/assets/powered-by-loreal-dermatological-beauty.png" alt="Powered by L'Oreal Dermatological Beauty">
        </div>
    </footer>
</template>

<script>
export default {
    mounted() {
        window.addEventListener('scroll', ()=>{
            this.buttonPosition();
        });
        setTimeout(this.buttonPosition, 500);
    },
    methods: {
        buttonPosition() {
            let footer = document.querySelector('footer');
            let box = footer.getBoundingClientRect();
            let footoff = window.innerHeight - box.bottom + box.height;
            footoff = (footoff < 0) ? "0px" : footoff + "px";
            document.body.style.setProperty('--footer-offset', footoff);
        }
    }
}
</script>
<style lang="scss">
    footer {
        background: var(--color-dark-blue);
        // border-top: 1px solid #b1b1b1;
        font-size: 0.825em;
        color: #ffffff;
        z-index: 100;
        
        .container {
            position: relative;
            margin: 0 auto;
            max-width: 1200px;
            padding: 25px 25px 0 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
    
            .copyright {
                font-size: .625em;
            }
    
            nav ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    display: inline;
                    padding: 0 0 0 1em;
                    white-space: nowrap;
                    a {
                        color: #ffffff;
                    }
                }
            }

            &.poweredby {
                padding: 0 25px 25px 25px;
                justify-content: flex-start;
                img {
                    width: 40%;
                    min-width: 280px;
                }
            }
        }
    }

    img.siteLogo {
        width: 130px;
    }
</style>