<template>
    <div class="cart-times">
        <slot></slot>
        <div class="button-panel">
            <div class="clickable morning" @click="toggleTime('morning')" :class="{selected : mytimes.morning}">Morning</div>
            <div class="clickable midday" @click="toggleTime('midday')" :class="{selected : mytimes.midday}">Midday</div>
            <div class="clickable evening" @click="toggleTime('evening')" :class="{selected : mytimes.evening}">Evening</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductTimeOfDay',
    setup() {
        
    },
    data() {
        return {
            mytimes: {
                morning: false,
                midday: false,
                evening: false
            }
        }
    },
    props: {
        'times': Object
    },
    mounted() {
        if (this.times) {
            // console.log("I have a time entry");
            this.mytimes = {...this.times}
        }
    },
    methods: {
        toggleTime(target) {
            this.mytimes[target] = !this.mytimes[target];
            this.$emit("time",target,this.mytimes[target]);
        },
    }
}
</script>

<style lang="scss">
    .cart-times {
        margin-top: 10px;
        font-size: .9375em;
        width: 90%;
        max-width: 18em;

        .button-panel {
            display: grid;
            grid-template-columns: repeat(3,1fr);

            div {
                --radius-size: .5em;
                --border-radius: 0;
                border-radius: var(--border-radius);
                position: relative;
                box-sizing: border-box;
                display: flex;
                //max-width: 5.5em;
                justify-content: center;
                align-items: center;
                color: var(--color-brand-blue);
                padding: .75em 12%;
                border: 1px solid var(--color-brand-blue);
                margin-right: -1px;

                &:first-of-type {
                    --border-radius: var(--radius-size) 0 0 var(--radius-size);
                }
                &:last-of-type {
                    --border-radius: 0 var(--radius-size) var(--radius-size) 0;
                }

                &.selected {
                    background: var(--color-product-image-bg-selected);
                    font-weight: bold;
                    color: var(--color-dark-blue);

                    &:after {
                        content: ' ';
                        box-sizing: border-box;
                        position: absolute;
                        width: calc(100% + 2px);
                        height: calc(100% + 2px);
                        margin: 0 -1px;
                        top: -1px;
                        left: 0;
                        border-radius: var(--border-radius);
                        border: 2px solid var(--color-dark-blue);
                    }
                }
            }
        }
    }
</style>