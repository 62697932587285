<template>
    <header>
        <div class="panel">
            <div class="container">
                <router-link to="/"><img class="siteLogo" src="@/assets/theReco.svg" alt="theReco" /></router-link>
                <div class="header_actions" v-if="isAuthenticated">
                    <a v-if="$route.meta.regimenComplete" class="newpatient" href="#" @click="newPatient">Start new patient</a>
                    <a v-if="!$route.meta.regimenComplete" class="reset" href="#" @click="resetForm">Reset form</a>
                    <a class="logout" href="#" @click="logout">Log out</a>
                </div>
            </div>
        </div>
    </header>
</template>

<style lang="scss" scoped>
    header {
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;
        overflow: visible;
        z-index: 100;

        @media screen and (max-width: 550px) {


            div.container {
                padding: 10px 20px !important;
                & > a {
                    max-width: 50%;
                }
            }
            a {
                position: relative;
            }
            div.header_actions {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                a {
                    padding: .4em 0;
                    white-space: nowrap;
                }
            }
        }

        @media screen and (max-width: 435px) {

            div.container {
                & > a {
                    flex: 1 1 auto;
                }
            }
            img.siteLogo {
                position: relative;
                width: 100% !important;
            }
        }

        &::before {
            position: absolute;
            z-index: 100;
            width: 100%;
            height: 100%;
            margin-left: 0;
            content: ' ';
            box-shadow: 0 0 1em black;
        }

        * {
            z-index: 110;
        }

        .panel {
            background: white;
            padding: 0;
            width: 100%;
        }

        div.container {
            background: white;
            padding: 30px 40px;
            margin: 0 auto;
            display: flex;
            font-size: 0.9375em;
            font-weight: bold;
            justify-content: space-between;
            align-items: center;
            max-width: 1440px;

            div.header_actions {
                a {
                    margin-left: 50px;
                    &::before {
                        content: ' ';
                        position: absolute;
                        margin-left: -1.25em;
                        margin-top: .15em;
                        width: 1em;
                        height: 1em;
                        background-position: left center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                    &.newpatient::before {
                        width: 15px;
                        background-image: url(@/assets/icon-newpatient.png);
                    }
                    &.reset::before {
                        background-image: url(@/assets/icon-reset.png);
                    }
                    &.logout::before {
                        background-image: url(@/assets/icon-logout.png);
                    }
                }
            }
        }

        img.siteLogo {
            width: 185px;
        }
    }
</style>

<script>
import { useAuthStore, useCartStore, useCatalogStore } from '@/stores/';
import router from '@/router';
export default {
    computed: {
        isAuthenticated() {
            const authStore = useAuthStore();
            return authStore.isAuthenticated;
        }
    },
    created() {
        window.addEventListener("resize", this.resizeEventHandler);
    },
    mounted() {
        setTimeout(()=>{
            this.resizeEventHandler();
        }, 500);
    },
    unmounted() {
        window.removeEventListener("resize", this.resizeEventHandler);
    },
    methods: {
        resizeEventHandler() {
            let headerHeight = (this.$route.meta.hideHeader) ? "0px" : document.querySelector('#TheReco header').clientHeight + "px";
            document.body.style.setProperty('--header-height',headerHeight);
        },
        logout() {
            console.log("Site Header Logout Method");
            const authStore = useAuthStore();
            authStore.logout();
            router.push('/');
        },
        newPatient() {
            const catalog = useCatalogStore();
            catalog.getCatalog();
            this.resetForm();
        },
        resetForm() {
            const cart = useCartStore();
            cart.resetCart();   
            router.push('/products')
        }
    }
}
</script>
